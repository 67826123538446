import { configureStore } from '@reduxjs/toolkit'
import {thunk} from 'redux-thunk';
import logger from 'redux-logger';
import { AuthedUser } from './reducers/authedUser';
import { AuthedAdmin } from './reducers/authedAdmin';
import { chatReducer } from './reducers/chat';


export const createStore = () => {

    const store = configureStore({
      reducer: {
        authedUser: AuthedUser,
        authedAdmin: AuthedAdmin,
        chat: chatReducer
      },
      middleware:  (getDefaultMiddleware) => getDefaultMiddleware().concat(logger).concat(thunk)
    });
    return store;
}
