import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../App";
import { updateUser } from "../../redux/ActionCreators";
import { useNavigate } from "react-router-dom";

export default function GenerateSDAddress(){
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getCustodialAddress = async () => {
    if (Object.keys(authedUser.authedUser).length > 0){
        try{
            setLoading(true);
            let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-sd-address`, {
                method: 'GET',
                headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
            }).then(r => r.ok ? r.json() : ({success: false, msg: 'Failed'}))
            if (res.success){
                dispatch(updateUser({...authedUser.authedUser, sdAddress: res.user.sdAddress}))
                showToast('Address generated');
            }
            else{
                showToast('Failed to get address');        
            }
            setLoading(false);
        }
        catch(e){
            console.log(e);
            setLoading(false);
        }
    }
    else{
        showToast("Login");
        navigate('/');
    }
  }

  return(
    <>
      <div className="heading my-2">Generate Address</div>
      <div className="sliderMain">
        <div className="inputRow">
          <div className="col1">
            {/* <div className="inputM mb-2">
              <input type="text" name="" id="" value={maxWithdrawAmount} onChange={e => setMaxAmount(e.target.value)}  placeholder="Amount" />
              <div className="balance">Balance: <span>{sdBalance.substring(0,7)}</span> ETH </div>
            </div>
            <div className="col2">
              <div className="fil" onClick={() => setMaxAmount(0.25*maxAmt)}>25%</div>
              <div className="fil" onClick={() => setMaxAmount(0.5*maxAmt)}>50%</div>
              <div className="fil" onClick={() => setMaxAmount(0.75*maxAmt)}>75%</div>
              <div className="fil" onClick={() => setMaxAmount(maxAmt)}>Max</div>
            </div>
            <div className="inputM mb-2">
              <input type="text" name="" id="external-wallet" placeholder="External Wallet Address" />
              <div className="balance" style={{cursor: 'pointer'}} onClick={pasteText}><span>Paste</span></div>
            </div> */}
            {/* <div className="inputM mb-2">
              <input type="text" name="" id="" value={authedUser?.authedUser?.sdAddress} placeholder="Stardust Wallet" />
              <div className="balance" style={{cursor: 'pointer'}} onClick={() => copyText(authedUser?.authedUser?.sdAddress)}><span>Copy</span></div>
            </div> */}
            <div className="sbtn mt-0 text-align-center" style={{textAlign: 'center'}}>
              <button className="themeBtn px-4" style={{fontSize: '20px'}} onClick={loading ? () => {} : getCustodialAddress}>{loading ? 'Generating...' : 'Get Your Wallet Address'}</button>      
              {/* <button className="themeBtn" onClick={loading ? () => {} : withdrawEth}>{loading ? 'Withdrawing...' : 'Submit'}</button> */}
            </div>
          </div>
       
        </div>
      </div>
    </>
    // <div>
    //   <h4>Generate Address</h4>
    //   {loading ? <div>Getting your address...</div> : 
    //   <button className="themeBtn" onClick={getCustodialAddress}>Get Your Wallet Address</button>      
    //   }
    // </div>
  );
}