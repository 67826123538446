import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalance } from "../../redux/ActionCreators";
import BigNumber from 'bignumber.js';
import { showToast } from "../../App";
import Slider from '@appigram/react-rangeslider'

export default function BuyPointsSection({getEthBalance, setDisabled, sdBalance, chain}){
  const authedUser = useSelector(s => s.authedUser);
  const [pointsValue, setPointsValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChangePoint = (value1) => {
    setPointsValue(value1)
  }

  const buyPoints = async() => {
    try {
        if(Object.keys(authedUser?.authedUser).length > 0){
            if (pointsValue <= 0){
              showToast('Points should be greater than 0');
              return;
            }
            let eth_val = new BigNumber(pointsValue * parseInt(process.env.REACT_APP_POINT_VAL)).dividedBy(new BigNumber(10**18));
            if (eth_val.isGreaterThanOrEqualTo(sdBalance)){
              showToast('Insufficient Balance');
              return;
            }
            setLoading(true);
            setDisabled(true);
            let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/purchase-points`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({points: pointsValue, chain: chain})
            }).then(r => r.ok ? r.json() : ({success: false, msg: 'Failed to buy points'}));
            if (res.success){
                dispatch(getUserBalance(authedUser.authedUser));
                getEthBalance();
                showToast('Success');
            }
            else{
                showToast(res.msg);
            }
            setLoading(false)
            setDisabled(false);
          }
        else{
            alert("please connect wallet");
        }
    } catch (error) {
        console.log(error);
        setDisabled(false);
        setLoading(false)
        showToast("Something went wrong");
    }
  }

  return(

    <>
    <div className="heading my-2">Buy <span>Honey Points</span></div>
    <div className="sliderMain">
      <div>
      <Slider
        min={1}
        max={10000}
        step={1}
        value={pointsValue}
        onChange={(e)=>handleChangePoint(e)}
        />
      </div>
      <div className="r1">
        <div className="col1">
          <div onClick={() => setPointsValue(2000)} className={`fil ${pointsValue === 2000 ? 'active' : ''}`}>2k</div>
          <div onClick={() => setPointsValue(5000)} className={`fil ${pointsValue === 5000 ? 'active' : ''}`}>5k</div>
          <div onClick={() => setPointsValue(7000)} className={`fil ${pointsValue === 7000 ? 'active' : ''}`}>7k</div>
          <div onClick={() => setPointsValue(10000)} className={`fil ${pointsValue === 10000 ? 'active' : ''}`}>10k</div>
        </div>
        <div className="col2">
          <div className="pointsVal"><span>{pointsValue}</span> Honey Points / <span>{(new BigNumber(process.env.REACT_APP_POINT_VAL)).dividedBy(new BigNumber(10**18)).multipliedBy(pointsValue).toString()}</span> ETH</div>
        </div>
      </div>
      <div className="r2">
        <div className="balance">Balance: <span>{sdBalance.substring(0,7)} ETH</span></div>
        <div className="themeBtn" style={{cursor: 'pointer'}} onClick={loading ? () => {} : buyPoints}>{loading ? 'Buying...' : 'Buy Points'}</div>
      </div>
    </div>
    </>
    // <div>
    //   <h4>Buy Honey Points</h4>
    //   <div>
    //     <input type="number" onChange={(e) => setPointsValue(e.target.value)} placeholder="Points to buy" />
    //     <button className="theme-btn" onClick={buyPoints}>Buy Points</button>
    //     {loading ? <div>Buying.....</div> : <></>}
    //   </div>
    // </div>
  );
}