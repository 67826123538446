import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { showToast } from '../../App';

const getRoundName = (round) => {
  if (round === '1') return 'Elimination Round';
  if (round === '2') return 'Knockout';
  if (round === '3') return 'Quarter Final';
  if (round === '4') return 'Semi Final';
  if (round === '5') return 'Final';
  if (round === '6') return 'Finished';
}

export default function TournamentRow({ tournament }){
  const [modalShow, setModalShow] = useState(false);
  const [timingModalShow, setTimingModalShow] = useState(false);
  const [games, setGames] = useState([]);
  const [currGames, setCurrGames] = useState([]);


  const getGames = () => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/tournaments/games/${tournament._id}`, {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
    }).then(r => r.json()).then(r => setGames(r.games));
  }

  useEffect(() => {
    getGames();
  }, []);

  const openTimingModal = () => {
    console.log(tournament);
    if (tournament.status === '1'){
      showToast('Tournament ended'); return;
    }
    if (tournament.round === '0'){
      showToast('Users not filled'); return;
    }
    let curr_games = games.filter(g => g.tournament_round === tournament.round).filter(g => !g.startTime);
    console.log(curr_games);
    if (curr_games.length > 0){
      setCurrGames(curr_games);
      setTimingModalShow(true);
    }
    else{
      showToast('No games available to set timings.');
    }
  }

  return(
    <>
      <tr key={tournament._id}>
        <td>
          {tournament.status === '0' ? 'In progress' : ''}
          {tournament.status === '1' ? 'Ended' : ''}
        </td>
        <td>{tournament.participants ? tournament.participants.length : '0'}/{process.env.REACT_APP_TOTAL_PARTICIPANTS}</td>
        <td>
          {tournament.round === '0' ? 'Waiting for users' : ''}
          {tournament.round === '1' ? 'Elimination Round' : ''}
          {tournament.round === '2' ? 'Knockout' : ''}
          {tournament.round === '3' ? 'Quarter Final' : ''}
          {tournament.round === '4' ? 'Semi Final' : ''}
          {tournament.round === '5' ? 'Final' : ''}
          {tournament.round === '6' ? 'Finished' : ''}
        </td>
        <td><button onClick={() => setModalShow(true)}>View Details</button></td>
        <td><button onClick={openTimingModal}>Set Timings</button></td>
      </tr>
      <Modal
          show={modalShow}
          onHide={() => setModalShow(false)} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
        <Modal.Body>
          <div className="betModal">
            <div className="closeIcon" onClick={() => setModalShow(false)}>
              <i className="fas fa-times"></i>
            </div>
            <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
              <h2 className="text-center">Tournament Details</h2>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <h4>Total Users</h4>
                  <div className='text-white'>{tournament.participants.length}</div>
                </div>
                {tournament.participants.map((p, i) => {
                  return(
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <h4>User # {i+1}</h4>
                      <div className='text-white'>{p.username}</div>
                    </div>
                  )
                })}
                <hr/>
                {games.length > 0 ? (
                  <>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <h4>Total Games</h4>
                      <div className='text-white'>{games.length}</div>
                    </div>
                    <hr/>
                    {games.map((g, i) => {
                      return(
                        <GameDetail game={g} key={`t-${i}`} />
                      )
                    })}
                  </>
                ) : (<></>)}

            </form>
          </div>
        </Modal.Body>
      </Modal>
      
      <Modal
          show={timingModalShow}
          onHide={() => setTimingModalShow(false)} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
        <Modal.Body>
          <div className="betModal">
            <div className="closeIcon" onClick={() => setTimingModalShow(false)}>
              <i className="fas fa-times"></i>
            </div>
            <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
              <h2 className="text-center">Set Timings for {getRoundName(tournament.round)} Matches </h2>
                {currGames.map((g, i) => {
                  return(
                    <SetTimingsRow games={games} setGames={setGames}
                    currGames={currGames} setCurrGames={setCurrGames}
                    key={`settime-${g}`} game={g} />
                  )
                })}
                <hr/>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const GameDetail = ({game}) => {
  console.log("-<>", game);
  return (
    <>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <h4>Status</h4>
      <div className='text-white'>
        {game.started ? (game?.winner ? 'Ended' : 'Started') : 'Not Started'}
      </div>
    </div>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <h4>Round</h4>
      <div className='text-white'>
          {game.tournament_round === '1' ? 'Elimination Round' : ''}
          {game.tournament_round === '2' ? 'Knockout' : ''}
          {game.tournament_round === '3' ? 'Quarter Final' : ''}
          {game.tournament_round === '4' ? 'Semi Final' : ''}
          {game.tournament_round === '5' ? 'Final' : ''}
          {game.tournament_round === '6' ? 'Finished' : ''}
      </div>
    </div>
    
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <h4>Start Time</h4>
      <div className='text-white'>
        {game.startTime ? `${(new Date(game.startTime)).getDate()}/${(new Date(game.startTime)).getMonth()+1}/${(new Date(game.startTime)).getFullYear()}` : 'Not Set'}
      </div>
    </div>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <h4>Participant # 01</h4>
      <div className='text-white'>
        {game.participants[0].username} {game.winner?._id === game.participants[0]._id ? ` - winner` : ''}
      </div>
    </div>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <h4>Participant # 02</h4>
      <div className='text-white'>
        {game.participants[1].username} {game.winner?._id === game.participants[1]._id ? ` - winner` : ''}
      </div>
    </div>

    <hr/>
    </>
  );
}

const SetTimingsRow = ({ game, games, setGames, setCurrGames, currGames }) => {
  let timeRef = useRef(null);
  const setTime = () => {
    let t = (new Date(timeRef.current.value)).toISOString();
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/tournaments/set-time`, {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({game_id: game._id, time: t})
    })
    .then(r => r.json()).then(r => {
      if (r.success){
        setCurrGames(currGames.map(c => c._id === r.game._id ? r.game : c));
        setGames(games.map(c => c._id === r.game._id ? r.game : c));
        showToast('Success');
      }
      else{
        showToast('Failed: '+r.msg);
      }
    });
  }

  return(
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Status</h4>
        <div className='text-white'>
          {game.started ? (game?.winner ? 'Ended' : 'Started') : 'Not Started'}
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Round</h4>
        <div className='text-white'>
            {game.tournament_round === '1' ? 'Elimination Round' : ''}
            {game.tournament_round === '2' ? 'Knockout' : ''}
            {game.tournament_round === '3' ? 'Quarter Final' : ''}
            {game.tournament_round === '4' ? 'Semi Final' : ''}
            {game.tournament_round === '5' ? 'Final' : ''}
            {game.tournament_round === '6' ? 'Finished' : ''}
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Participant # 01</h4>
        <div className='text-white'>
          {game.participants[0].username} {game.winner?._id === game.participants[0]._id ? ` - winner` : ''}
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Participant # 02</h4>
        <div className='text-white'>
          {game.participants[1].username} {game.winner?._id === game.participants[1]._id ? ` - winner` : ''}
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Start Time</h4>
        <div className='text-white'>
          {game.startTime ? (
            <>
              {(new Date(game.startTime).getDate())}/{(new Date(game.startTime).getMonth()+1)}/{(new Date(game.startTime).getFullYear())}{' '}{(new Date(game.startTime)).getHours()}:{(new Date(game.startTime)).getMinutes()}
            </>
          ) : ('Not Set')}
        </div>
      </div>
      <div className="col-12 form-group mt-2">
        <label>Start Time:</label><br/>
        <input ref={timeRef} type="datetime-local" />
        <br/>
        <button className='mt-2' onClick={setTime}>Set Time</button>
      </div>
      <hr/>
    </>
  );
}