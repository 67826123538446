import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { setDiscordUser } from "../../redux/ActionCreators";
import { showToast } from "../../App";


export default function DiscordFetch () {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("asdsa", searchParams.get("code"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
	let flag = false;

  useEffect(() => {
		console.log(searchParams.get("code") && !!localStorage.getItem('token'), searchParams.get("state").split("321123"));
    if (searchParams.get("code") && !!localStorage.getItem('token')) {
      dispatch(setDiscordUser(searchParams.get("code"), searchParams.get("state")))
      .then((res) => {
        console.log(res, res?.type === "LOGGED_IN");
        if (res?.type === "LOGGED_IN") {
          navigate(`/user`, {state: {tab: 'settings'}});
        } else {
          navigate("/");
					showToast("Failed to connect discord");
        }
      });
    }
  }, []);

  if (searchParams.get("code") && !!localStorage.getItem('token')) {
    return (
      // <Navigate to={`/profile/${searchParams.get('state')}`} />
      <></>
    );
  } else {
    return <Navigate to="/" />;
  }
};