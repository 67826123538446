import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/ActionCreators";
import TimeRemaining from './timeRemaining'
import EnterPoolModal from './enterPoolModal'
import CreatePoolModal from './createPoolModal'
import watch from '../../imgs/watch.svg'
import cards from '../../imgs/cards.svg'
import { useNavigate } from "react-router-dom";
import { showToast } from "../../App";

export default function RumbleDetail({ detail, id }){
  let authedUser = useSelector(state => state.authedUser);
  const createPoolRef = useRef(null);
  const [pools, setPools] = useState([]);
  const [selectedPool, setSelectedPool] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleCloseCreate = () => setShowCreate(false);
	const handleShowCreate = () => setShowCreate(true);
  
  useEffect(() => {
    if (id){
      getPools();
    }
  }, [id]);

  const getPools = async () => {
    if (id){
        let pls;
        try{
            pls = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-pools/${id}?type=rumble`)
            .then(r => r.json()).then(r => r.success ? r : ({pools: []}));
        }catch(e){}
        if (!!pls){
            setPools(pls.pools);
            if (pls.pools.length > 0){setSelectedPool(pools[0])};
        }else{
            showToast('Failed to fetch');
            navigate('/prediction/football');
        }
    }
    else{
        showToast('Invalid Football Game');
        navigate('/prediction/football');
    }
  }

  const createPool = async () => {
    if (Object.keys(authedUser?.authedUser).length > 0){
        let pts = createPoolRef.current.value;
        console.log(pts, { id, points: pts });
        if (pts){
            let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/create-pool`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({ id, points: pts, type: 'rumble' })
            }).then(r => r.ok ? r.json() : ({success: false, msg: 'Failed'}));
            if (res.success){
                createPoolRef.current.value = null;
                if (pools.length === 0){setSelectedPool(res.pool)}
                setPools(pools.concat([res.pool]));
                handleCloseCreate();
                showToast('Success');
            }
            else{
                showToast(res.msg);
            }
        }
        else{showToast('Please enter points for your pool entry')}
    }
    else{
        showToast('Connect Wallet');
    }
  }
  
  const enterPool = async (expWinner) => {
    if (Object.keys(authedUser.authedUser).length > 0){
        if ((new Date(detail?.endTime)) > (new Date())){
            if (Object.keys(selectedPool).length > 0){
                if (authedUser.authedUser.points > parseFloat(selectedPool.points)){
                    let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/enter-pool`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type' : 'application/json'
                        },
                        body: JSON.stringify({ id, p_id: selectedPool._id, expWinner })
                    }).then(r => r.ok ? r.json() : ({success: false, msg: 'Failed'}));
                    if (res.success){
                        handleClose();
                        showToast('Success');
                        dispatch(updateUser({...authedUser.authedUser, points: authedUser.authedUser.points - parseFloat(selectedPool.points)}));
                    }else{showToast(res.msg);}
                }else{showToast('Not enough points');}
            }else{showToast('Select a pool first');}
        }else{showToast('Game is ended');}
    }else{showToast('Connect Wallet');}
  }

  return(
    <main className='px-3 footballPage vipPageCenter'>
      <section className="bannerSec">
          {/* <h1 className='animatable slideInUp'>Football</h1> */}
      </section>
      <section className='sec2 pt-0'>
          <h1 className='animatable slideInUp'>
            Rumble
          </h1>
      </section>
      <div className="poolCardMain">
          <div className="fooCard">
              <div className="imgDiv">
                  <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${detail?.teamA?.img}`} alt="" />
              </div>
              <div className="title">{detail?.teamA?.name}</div>
          </div>
          <div className="vs">vs</div>
          <div className="fooCard">
              <div className="imgDiv">
                  <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${detail?.teamB?.img}`} alt="" />
              </div>
              <div className="title">{detail?.teamB?.name}</div>
          </div>
      </div>  
      <div className="poolCard">
          <div className="row1">
              <div className="title"><img className='me-2' src={watch} alt="" /> Full Time Results</div>
              {(new Date(detail?.endTime) > new Date()) ? <TimeRemaining time={detail?.endTime} /> : <div>Ended</div>}
          </div>
          <div className="row2">
              <div className="scoreM">
                  <button className="available creatBtn" onClick={handleShow}>Pools Available</button>
              </div>
              <div className="scoreM">
                  <button className="available creatBtn" onClick={handleShowCreate}>Create Pool</button>
              </div>
              <div className="scoreM">
                  <div className="name">{detail?.teamA?.name}</div>
                  <div className="score">{detail?.teamAGoals} Goals</div>
              </div>
              <div className="scoreM">
                  <div className="name">{detail?.teamB?.name}</div>
                  <div className="score">{detail?.teamBGoals} Goals</div>
              </div>
          </div>
      </div>
      <div className="poolCard">
          <div className="row1">
              <div className="title"><img src="" alt="" /> Corners</div>
              <button className="creatBtn">Create Pool</button>
          </div>
          <div className="row2">
              <div className="scoreM">
                  <div className="name">Real Madrid</div>
                  <div className="score">0/0</div>
              </div>
              <div className="scoreM">
                  <div className="name">Draw</div>
                  <div className="score">0/0</div>
              </div>
              <div className="scoreM">
                  <div className="name">Barcelona</div>
                  <div className="score">0/0</div>
              </div>
          </div>
      </div>
      <div className="poolCard">
          <div className="row1">
              <div className="title"><img className='me-2' src={cards} alt="" /> Cards</div>
              <button className="creatBtn" onClick={handleShow} >Create Pool</button>
          </div>
          <div className="row2">
              <div className="scoreM">
                  <div className="name">Real Madrid</div>
                  <div className="score">0/0</div>
              </div>
              <div className="scoreM">
                  <div className="name">Draw</div>
                  <div className="score">0/0</div>
              </div>
              <div className="scoreM">
                  <div className="name">Barcelona</div>
                  <div className="score">0/0</div>
              </div>
          </div>
      </div>

      <EnterPoolModal enterPool={enterPool} gameDetail={detail} show={show} handleClose={handleClose} pools={pools} selectedPool={selectedPool} setSelectedPool={setSelectedPool} />
      <CreatePoolModal show={showCreate} handleClose={handleCloseCreate} createPoolRef={createPoolRef} createPool={createPool} />
      {/* <Footer /> */}
    </main>
  );
}