import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from 'react-bootstrap'

export default function PoolEntryRow({ pool, setSelectedPool }){
  let [entries, setEntries] = useState(0);
  useEffect(() => {
    getEntries().then(r => setEntries(r));
  }, []);
  const getEntries = async () => {
    return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-pool-entries/${pool._id}`).then(r => r.json()).then(r => r.entries);
  }
  return(
    <Dropdown.Item disabled={entries === 2} onClick={() => setSelectedPool(pool)}>
      {pool.points} PTS ({pool.createdBy ? (pool.createdBy.username) : 'default'})
    </Dropdown.Item>
  );
}