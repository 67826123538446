import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import logoImg from '../../imgs/orange-logo.svg';
import statsIcon from '../../imgs/stats-icon.svg';
import line from '../../imgs/stats-line.png';
import { useSelector } from "react-redux";

export default function StatsDetails(){
  let [stats, setStats] = useState({});
  let authedUser = useSelector(s => s.authedUser);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/personal-stats`, {
      method: 'GET', 
      headers: {
        'Authorization':`Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(r => r.json()).then(r => r.success ? setStats(r.stats) : '');
  }, []);
  
  return(
    <Row className="stats-details" style={{border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '19px'}}>
      <Col xs='3' className={`stats-detail-btn`}>
        <img src={logoImg} />
        <div className="stats-text-container">
          <span className="stats-num">{parseFloat(authedUser?.authedUser?.points?.toFixed(4))}</span>
          <span className="stats-text">Honey Points</span>
        </div>
      </Col>
      <Col xs='3' className={`stats-detail-btn`}>
        <img src={statsIcon} />
        <img src={line} className="stats-line" />
        <div className="stats-text-container">
          <span className="stats-num">{stats?.totalGames}</span>
          <span className="stats-text">Total Bets</span>
        </div>
      </Col>
      <Col xs='3' className={`stats-detail-btn`}>
        <img src={logoImg} />
        <img src={line} className="stats-line" />
        <div className="stats-text-container">
          <span className="stats-num">{stats?.totalDeposit}</span>
          <span className="stats-text">Total Deposit</span>
        </div>
      </Col>
      <Col xs='3' className={`stats-detail-btn`}>
        <img src={logoImg} />
        <img src={line} className="stats-line" />
        <div className="stats-text-container">
          <span className="stats-num">{stats?.totalWithdrawn}</span>
          <span className="stats-text">Total Withdrawn</span>
        </div>
      </Col>
    </Row>
  );
}