import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AdminNavbar } from './adminNavbar';

export const UserPointsPage = () => {
    const [userPointsData,setUserPointsData] = useState([])
    const [PageNum,setPageNum] = useState(1)

    const getUserPoints = async() => {
        const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get-all-user-points`;
        let response = await axios.get(url,
            {headers: {
                'Authorization': localStorage.getItem('admin')
            }}
        );

        if(response.data.success){
            console.log(response.data);
            setUserPointsData(response.data.response || [])
        }

    }

    const pointsPerPage=10

    useEffect(() => {
        getUserPoints()
    }, [])
    

  return (
    <div>
      <AdminNavbar />
   <section className="createBetPage">
        <div className="container-fluid">
          <div className="table-responsive mt-4 homeTable">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th scope="col">Username</th>
                  <th scope="col">points</th>
                </tr>
              </thead>
              <tbody>
                  {userPointsData.slice(0, pointsPerPage*PageNum) .map((item,key)=>(
                  <TableRow item={item} key={key} />
                  ))}
              
              </tbody>
            </table>
          </div>
          {userPointsData.length > pointsPerPage*PageNum  &&
          <div className="createBetBtn text-center">
              <button className="themeBtn" onClick={()=>setPageNum(PageNum+1)}>Show More</button>
          </div>
          }
        </div>
    </section>

  </div>
  )
}

const TableRow =({item,key}) => {
    return(
      <tr key={key}>
          <td>
            {item?.user?.username}
          </td>
          <td>{item?.points}</td>
      </tr>
    )
  }
