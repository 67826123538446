import Web3 from 'web3';




let web3;
// import { MoralisProvider } from "react-moralis";

if (window.ethereum){
  // if (parseInt(window.ethereum.chainId) === parseInt(process.env.REACT_APP_CHAIN_ID) || parseInt(window.ethereum.chainId) === parseInt(process.env.REACT_APP_ETH_CHAIN_ID)){
  if (parseInt(window.ethereum.chainId) === parseInt(process.env.REACT_APP_CHAIN_ID) || parseInt(window.ethereum.chainId) === parseInt(process.env.REACT_APP_ETH_CHAIN_ID)){
    web3 = new Web3(window.ethereum);
  }
  else{
    web3 = new Web3(process.env.REACT_APP_ETH_RPC_URL);
  }
}
else{
  web3 = new Web3(process.env.REACT_APP_ETH_RPC_URL);
}
let a = {
  web3: web3
};

// let a = {
//   web3: new Web3(process.env.REACT_APP_NODE_URL_RPC)
// };
export default a;