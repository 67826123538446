import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReferImg from '../../imgs/refer-img.svg';
import logo from '../../imgs/orange-logo.svg';
import mail from '../../imgs/mail-refer.svg';
import twit from '../../imgs/twitter-refer.svg';
import teleg from '../../imgs/Telegram-refer.svg';
import refSignIcon from '../../imgs/refSignIcon.svg';
import refSuccessIco from '../../imgs/refSuccessIco.svg';
import refCreditICo from '../../imgs/refCreditICo.svg';
import { useDispatch, useSelector } from "react-redux";
import { animate } from "../../js/animate";
import { showToast } from "../../App";
import { updateUser } from "../../redux/ActionCreators";

export default function RefferalsSection(){
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();
  const [referrals, setReferrals] = useState([]);
  let [stats, setStats] = useState({});

  const fetchReferralStats = () => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/refer-stats`, {
      method: 'GET', 
      headers: {
        'Authorization':`Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(r => r.json()).then(r => r.success ? setStats(r.stats) : '');
  }
  
  const fetchReferrals = async() => {
    if (Object.keys(authedUser?.authedUser).length > 0){
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-referrals`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(r => {
            if (r.ok){return r.json();}
            else {return {success: false, msg: 'Unknown'}}
        }).then(res => {
            if (res.success){
                setReferrals(res.referrals);
            }
            else{
                console.log('Failed to fetch referrals: '+res.msg);
            }
        })    
    }
  }
  useEffect(()=>{
    animate();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  },[]);
  useEffect(() => {
    fetchReferrals();
    fetchReferralStats();
  }, [authedUser.authedUser]);

  const getReferralCode = () => {
    if (authedUser?.authedUser?._id){
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-referral-code`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        }).then(r => {
            if (r.ok){return r.json()}
            else{return {success: false, msg: 'Failed to get referral'}}
        }).then(r => {
            if (r.success){
                dispatch(updateUser({...authedUser.authedUser, referralCode: r.code}));
            }
            else{
                showToast(r.msg);
            }
        })
    }
    else{
        showToast('Connect Wallet');
    }
  }
  const copyLink = () => {
    navigator.clipboard.writeText(`https://${window.location.host}/?code=${authedUser?.authedUser?.referralCode}`);
    showToast('Copied');
  }
  const claimReferral = async (uid) => {
    if (Object.keys(authedUser?.authedUser).length > 0){
      return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/claim-referral`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({uid})
      }).then(r => {
        if (r.ok){
          return r.json()
        }
        else{
          return {success: false, msg: 'Failed to claim referral'}
        }
      }).then(r => {
        if (r.success){
          dispatch(updateUser({...authedUser.authedUser,points:authedUser.authedUser.points+r.points}));
        }
        else{
          showToast(r.msg);
        }
      });
    }
    else{
      showToast('Login');
    }
  }

  const claimAll = async () => {
    if (Object.keys(authedUser.authedUser).length > 0){
      let unclaimedReferrals = referrals.filter(r => ((r.discord && r.twitter) && !r.referralClaimed));
      if (unclaimedReferrals.length === 0){
        showToast('No reward to claim from referrals');
      }
      else{
        for (let i = 0; i<unclaimedReferrals.length;i++){
          let u = unclaimedReferrals[i];
          await claimReferral(u._id);
        }
        fetchReferralStats();
      }
    }
    else{
      showToast('Login');
    }
  }

  return(
    <>
    <Row className="mt-4 mx-4" style={{border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '19px'}}>
      <Col xs='12' className="refer-header" style={{border: '1px solid rgb(251, 176, 64, 0.7)', borderRadius: '19px', display: "flex", alignItems: 'center', padding: '35px 100px'}}>
        <img src={ReferImg} />
        <div>
          <div style={{fontSize: '48px', fontWeight: '900', fontFamily: 'GothamLight'}}><span style={{fontFamily: 'GothamBold'}}>REFER</span> & EARN</div>
          <div style={{color: '#C9C9C9',  fontFamily: 'GothamLight'}}>The <span style={{color: '#FBB040'}}>referrer earns $BEE tokens</span> by sharing their unique referral codes, while the referred <span style={{color: '#FBB040'}}>user receives $BEE token bonuses.</span> These bonuses can be used within the platform, providing an incentive for users to explore and try out the diverse array of games available.</div>
        </div>
      </Col>
      <Col xs='12' className="my-4" style={{justifyContent: 'center', display: 'flex'}}>
        <div className="my-4" style={{display: 'flex', width: 'fit-content',border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '30px'}}>
          <div xs='8' style={{display: 'flex', padding: '15px 50px', alignItems: 'center', justifyContent: 'center', border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '30px'}}>
            <img src={logo} />
            <span style={{color: 'white',fontFamily: 'GothamBold', fontSize: '48px'}}>{stats.creditsEarned}</span>
            <span style={{color: '#77797E', fontFamily: 'GothamLight', fontSize: '28px', marginLeft: '10px'}}>$BEE</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{ padding: '30px',}} className="claim-div">
              <button style={{fontFamily: 'GothamBold', borderRadius: '10px', padding: '10px 12px'}} onClick={claimAll} className="theme-btn">Claim Rewards</button>
            </div>
          </div>
        </div>
      </Col>
      <Col xs='12' className="my-4" style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        <div style={{color: 'white' , marginBottom: '25px'}}>
          Share my referral link with friends
        </div>
        <div style={{display: 'flex', justifyContent: authedUser?.authedUser?.referralCode ? 'space-between' : 'center', width: '100%'}}>
          {authedUser?.authedUser?.referralCode ? (
            <>
              <span style={{color: '#F2F2F2', width: '88%', backgroundColor: '#181818', borderRadius: '19px 0px 0px 19px', display: 'flex', alignItems: 'center', paddingLeft: '10px', fontFamily: 'GothamLight'}}>
                https://{window.location.host}/?code={authedUser?.authedUser?.referralCode}
                {/* https://bee...{authedUser?.authedUser?.referralCode.substring(10)} */}
              </span>
              <button className="theme-btn" onClick={copyLink} style={{borderRadius: '0px 19px 19px 0px', fontFamily: 'GothamBold', padding: '10px 24px'}}>
                Copy Link
              </button>
            </>          
          ) : (
            <>
              <button className="theme-btn" onClick={getReferralCode} style={{borderRadius: '19px', fontFamily: 'GothamBold', padding: '10px 24px'}}>
                Get Referral Link
              </button>
            </>
          )}
        </div>
        <div style={{display: "flex", marginTop: '20px'}}>
          <span style={{color: 'white', fontFamily: 'GothamBold', marginRight: '10px'}}>Share at:</span>
          <div>
            <img src={mail} style={{height: '20px', marginRight: '10px', marginLeft: '10px'}} />
            <img src={twit} style={{height: '20px', marginRight: '10px', marginLeft: '10px'}} />
            <img src={teleg} style={{height: '20px', marginRight: '10px', marginLeft: '10px'}} />
          </div>
        </div>
      </Col>
      <Col xs='12'>
      <div className="table-responsive refTable">
        <table className="table custom-table">
          <thead>
          <tr>
              <th>Address</th>
              <th>Twitter</th>
              <th>Discord</th>
              <th>Claim</th>
          </tr>
          </thead>
          <tbody style={{verticalAlign: 'middle'}}>
          {referrals.map((r, _) => (
              <tr key={`${_}-${r._id}`}>
                  <td style={{backgroundColor: 'rgb(36, 36, 36)'}}>{r.username}</td>
                  <td style={{backgroundColor: 'rgb(36, 36, 36)'}}>{r.discord ? 'Joined' : 'Not Joined'}</td>
                  <td style={{backgroundColor: 'rgb(36, 36, 36)'}}>{r.twitter ? 'Joined' : 'Not Joined'}</td>
                  <td style={{backgroundColor: 'rgb(36, 36, 36)'}}>
                      {(!!r.discord && !!r.twitter) ? (
                          r.referralClaimed ? ("Claimed") : (
                              <button onClick={() => claimReferral(r._id)} className='themeBtn' style={{padding: '5px 30px'}}>Claim</button>
                          )
                      ) : (
                          "-"
                      )}
                  </td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>

      </Col>
    </Row>

    <Row className="my-4 mx-4 referalStatsRow" style={{border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '19px'}}>
      <Col xs='12' md='4' >
        <div className="referalStatsCard">
          <img src={refSignIcon} alt="" className="ico" />
          <div>
            <div className="number">{stats.signups}</div>
            <div className="title">Referrel Signups</div>
          </div>
        </div>
      </Col>
      <Col xs='12' md='4' >
        <div className="referalStatsCard">
          <img src={refSuccessIco} alt="" className="ico" />
          <div>
            <div className="number">{stats.successfulRefers}</div>
            <div className="title">Successful Referrals</div>
          </div>
        </div>
      </Col>
      <Col xs='12' md='4' >
        <div className="referalStatsCard" style={{border:'none'}}>
          <img src={refCreditICo} alt="" className="ico" />
          <div>
            <div className="number">{stats.creditsEarned}</div>
            <div className="title">Credit Earned</div>
          </div>
        </div>
      </Col>
    </Row>
    </>
  );
}