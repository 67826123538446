import * as ActionTypes from '../ActionTypes';

export const chatReducer = (state = {
  serverMessages: [],
  liveMessages: [],
  chatNotifications: [],
  chatMessageFocus: ''
}, action) => {
  switch(action.type) {
    case ActionTypes.SET_SERVER_MESSAGES:
      return {
        ...state,
        serverMessages: action.payload
      }
    case ActionTypes.SET_CHAT_NOTIFICATIONS:
      return {
        ...state,
        chatNotifications: action.payload
      }
    case ActionTypes.ADD_LIVE_MESSAGE:
        return {
          ...state,
          liveMessages: [...state.liveMessages, action.payload]
        }
    case ActionTypes.SET_CHAT_MESSAGE_FOCUS:
        return {
          ...state,
          chatMessageFocus: action.payload
        }
    default:
      return state;
  }
}