import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './css/animate.css';
import Main from './main';
import { createStore } from './redux/configureStore';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';

const store = createStore();

export const showToast = (msg) => (
  toast(msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: 'dark'
  })
)

function App() {
  return (
    <Provider store={store}>
      <Main/>
    </Provider>
  );
}

export default App;
