import React, { useState } from 'react'
import logo from "../../imgs/logo_beecasino.png"
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import web3 from "../../web3"
import Web3 from 'web3';
import { loginAdmin, logoutAdmin } from '../../redux/ActionCreators';

export const AdminNavbar = () => {
  const authedAdmin = useSelector((state) => state.authedAdmin);
  const [connectedAddress, setConnectedAddress] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const connectWallet = async () => {
    if (!window.ethereum){
      alert('Install Metamask');
    }
    else if (parseInt(window.ethereum.chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)){
      alert(`Connect to ${process.env.REACT_APP_NETWORK_NAME}`);
    }
    else{
      try{
        let accs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        console.log(accs);
        if (accs.length > 0){
          if (accs[0].toLowerCase() === process.env.REACT_APP_ADMIN_ADDRESS.toLowerCase()){
            web3.web3 = new Web3(window.ethereum);
            let updatePayload = authedAdmin?.authedAdmin
            updatePayload.user.wallet = accs[0]
            setConnectedAddress(accs[0]);
            console.log(updatePayload.user);
           dispatch(loginAdmin(updatePayload));

            console.log(web3.web3);
          }
          else{
            alert('Connect admin wallet');
          }
        }
        else{
          alert('Connect Wallet');
        }
      }
      catch(e){
        console.log(e);
        alert('Connect Wallet');
      }
    }
  }

  const logout = async () => {
    try {
      await dispatch(logoutAdmin({}));
      localStorage.clear();
      setConnectedAddress('');
      navigate("/admin-login");
    } catch {
      alert.show("Error while logging out");
    }
  };

  return (
    <>
    <nav className="navbar navbar-expand-lg bb-gray navbar-dark">
        <div className="container-fluid">
        <a className="navbar-brand" href="#">
        <img className="logo" src={logo} alt="" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
                <li className="nav-item active">
                    <NavLink className="nav-link" to="/create-bet" >Home</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/all-counts" >All Counts</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/user-points" >User Points</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/user-joined" >User Joined</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/wallet-generated" >Wallet Generated</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/played-game" >Played Game</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/most-played-game" >Most Played Game</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/jackpot-balances" >Jackpot Balances</NavLink>
                </li>
            </ul>
        </div>
        {/* {connectedAddress? */}
        <button className="themeBtn" onClick={logout}>Logout</button>
        {/* :
        <div className="themeBtn" onClick={connectWallet}>Connect Wallet</div>
        } */}
        </div>
    </nav>

    </>
  )
}
