import React, { useState } from "react";
import { Dropdown, Modal } from 'react-bootstrap'


export default function EnterPoolModal({show, handleClose, pools, selectedPool, setSelectedPool, enterPool, gameDetail}){
  let [winner, setWinner] = useState('');
  return(
    <Modal id="WalletModal" className="WalletModal predModal" show={show} onHide={handleClose} centered >
      <Modal.Body>
          <div className='betOptionsModal'>
              <h1>Bet Options</h1>
              <div>
                  <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic" className='dropD'>
                          {selectedPool?.points ? selectedPool?.points : '-'} POINTS
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='dropD m-0'>
                          {pools.map(pool => (
                              <Dropdown.Item onClick={() => setSelectedPool(pool)} key={pool._id}>{pool.points} POINTS ({pool.createdBy ? pool.createdBy.username : 'default'})</Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                  </Dropdown>

                  
                  <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic" className='dropD'>
                            {winner?.name ? winner?.name : 'Draw'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='dropD m-0'>
                        <Dropdown.Item onClick={() => setWinner({})}>Draw</Dropdown.Item>
                        <Dropdown.Item onClick={() => setWinner(gameDetail?.teamA)}>{gameDetail?.teamA?.name}</Dropdown.Item>
                        <Dropdown.Item onClick={() => setWinner(gameDetail?.teamB)}>{gameDetail?.teamB?.name}</Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
              </div>
              <button onClick={() => enterPool(winner._id ? winner._id : 'draw')} className="continue">Continue</button>
          </div>
      </Modal.Body>
    </Modal>
  );
}