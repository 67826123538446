import React from "react";
import { Col, Row } from "react-bootstrap";

export default function TabsSection({ activeTab, setActiveTab }){
  return(
    <Row className="px-5">
      <Col xs='12'>
        <Row className="profile-tab-btns">
          <Col xs='3' onClick={() => setActiveTab('stats')} className={`profile-tab-btn ${activeTab === 'stats' ? 'active' : ''}`} style={{borderRadius: '15px 0px 0px 15px', borderLeft: '1px solid rgb(63, 63, 63, 0.5)'}}>
            Stats
          </Col>
          <Col xs='3' onClick={() => setActiveTab('referrals')} className={`profile-tab-btn ${activeTab === 'referrals' ? 'active' : ''}`}>
            Referrals
          </Col>
          <Col xs='3' onClick={() => setActiveTab('friends')} className={`profile-tab-btn ${activeTab === 'friends' ? 'active' : ''}`}>
            Friends
          </Col>
          <Col xs='3' onClick={() => setActiveTab('settings')} className={`profile-tab-btn ${activeTab === 'settings' ? 'active' : ''}`} style={{borderRadius: '0px 15px 15px 0px',  borderRight: '1px solid rgb(63, 63, 63, 0.5)'}}>
            Settings
          </Col>
        </Row>
      </Col>
    </Row>
  );
}