import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import LeaderboardCup from '../../imgs/leaderboard-cup.svg';
import LeaderboardDDBtn from '../../imgs/leaderboard-dd-btn.svg';
import LeaderboardPfp from '../../imgs/leaderboard-pfp.png';
import LeaderboardTime from '../../imgs/leaderboard-time.svg'
import LeaderboardTotalBets from '../../imgs/leaderboard-total-bets.svg'
import LeaderboardTotalPts from '../../imgs/leaderboard-total-pts.svg'

export default function LeaderboardSection(){
  let [gameName, setGameName] = useState('Dice');
  let [time, setTime] = useState('all');
  let [stats, setStats] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/game-stats/${gameName.toLowerCase()}/${time}`)
    .then(r => r.json())
    .then(r=>{
      if (r.success){
        setStats(r.stats.sort((a,b) => a.pts - b.pts));
      }
    })
  }, [time, gameName]);

  return(
    <Row className="mt-4" style={{border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '19px'}}>
      <Col xs='12' className="leaderboard-header" style={{border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '19px'}}>
        <div className="leaderboard-header-left">
          <img src={LeaderboardCup} />
          <div className="leaderboard-header-text">
            <span className="leaderboard-text">Leaderboard</span>
            <span className="leaderboard-rank-text">Rank</span>
          </div>
        </div>
        <Dropdown className="leaderboard-dd">
            <Dropdown.Toggle id="leaderbaord-dropdown-basic">
              {gameName === 'rockpaper' ? 'RPS' : gameName}
              <img src={LeaderboardDDBtn} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setGameName('Dice')} >Dice</Dropdown.Item>
              <Dropdown.Item onClick={() => setGameName('rockpaper')} >RPS</Dropdown.Item>
              <Dropdown.Item onClick={() => setGameName('Connect4')} >Connect4</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
      </Col>
      <Col xs='12'>
        <Row>
          <Col xs='12' className="p-4">
            <div className="leaderboard-pre-table">
              <div style={{fontSize: '22px'}}>{gameName === 'rockpaper' ? 'RPS' : gameName} Leaderboard</div>
              <div style={{padding: '12px 0px', fontFamily: 'GothamLight', fontSize: '15px', color: '#47464A', borderRadius: '12px', backgroundColor: '#000000', border: '1px solid #202020',}}>
                <span className={time === 'all' ? 'active' : ''} onClick={() => setTime('all')} style={{cursor: 'pointer', padding: '0px 20px'}}>All time</span>
                <span className={time === 'month' ? 'active' : ''}onClick={() => setTime('month')} style={{cursor: 'pointer', padding: '0px 20px'}}>This month</span>
                <span className={time === 'week' ? 'active' : ''}onClick={() => setTime('week')} style={{cursor: 'pointer', padding: '0px 20px'}}>This week</span>
              </div>
            </div>
            <table style={{width: "100%", color: 'white', borderCollapse: 'separate', borderSpacing: '10px'}}>
              <colgroup>
                <col span="1" style={{width: "5%"}}/>
                <col span="1" style={{width: "50%"}}/>
                <col span="1" style={{width: "15%"}}/>
                <col span="1" style={{width: "15%"}}/>
                <col span="1" style={{width: "15%"}}/>
              </colgroup>
  
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Total Time</th>
                  <th>Total Bets</th>
                  <th>Total Points</th>
                </tr>
              </thead>
              <tbody>
                {stats.map((stat, i) => {
                  return(
                    <tr key={`${i}-${stat._id._id}-${gameName}-${time}`}>
                      <td>{i+1}</td>
                      <td>
                        <img style={{width: '35px', minWidth: '35px', marginRight: '10px', height: '35px', borderRadius: '50%'}} src={stat._id.profilepic ? `${process.env.REACT_APP_SERVER_BASE_URL}/images/${stat._id.profilepic}` : LeaderboardPfp}  />
                        {stat._id.username}
                      </td>
                      <td>
                        <img style={{marginRight: '10px'}} src={LeaderboardTime}  />
                        192 hours
                      </td>
                      <td>
                        <img style={{marginRight: '10px'}} src={LeaderboardTotalBets}  />
                        {stat.games} bets
                      </td>
                      <td>
                        <img style={{marginRight: '10px'}} src={LeaderboardTotalPts}  />
                        {stat.pts.toFixed(3)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}