import React, { useEffect, useState } from 'react'
import { animate } from '../../js/animate'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import RumbleDetail from './rumbleDetail'
import PvpDetail from './pvpDetail'
import { showToast } from '../../App'

export const FootballDetailPage = () => {
    let { id } = useParams();
    let [type, setType] = useState('');
    let [searchParams,setSearchParams]= useSearchParams();

    const [detail, setDetail] = useState({});

    let navigate = useNavigate();


    useEffect(() => {
        animate()
        // window.scrollTo(0, 0,'instant')
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
        if (['pvp', 'rumble'].includes(searchParams.get('type'))){
            setType(searchParams.get('type'));
        }
        else{
            setType('rumble');
            setSearchParams(['type', 'rumble']);
        }
    }, []);
    useEffect(() => {
        if (type){
            getGame(type);
        }
    }, [type]);

    const getGame = async (type) => {
        if (id){
            let gameDetail;
            try{
                gameDetail = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-game/${id}?type=${type}`)
                .then(r => r.json()).then(r => r.success ? r : ({}));
            }catch(e){}
            if (gameDetail.game){
                setDetail(gameDetail.game);
            }else{
                showToast('Failed to fetch');
                navigate('/prediction/football');
            }
        }
        else{
            showToast('Invalid Football Game');
            navigate('/prediction/football');
        }
    }

    if (type === 'rumble'){
        return (
            <RumbleDetail id={id} detail={detail} /> 
        )
    }
    else if (type === 'pvp'){
        return(
            <PvpDetail id={id} detail={detail} />
        );
    }
    else{
        return(<></>);
    }
}

