import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AdminNavbar } from './adminNavbar';

export const AllCounts = () => {
    const [gamePlayed,setGamePlayed] = useState([])
    const [PageNum,setPageNum] = useState(1)
    const [filterBy,setFilterBy] = useState('day')
    const [countData,setCountData] = useState({})

    const allCountsData = async() => {
        const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stats/all-counts/${filterBy}`;
        let response = await axios.get(url,
            {headers: {
                'Authorization': localStorage.getItem('admin')
            }}
        );

        if(response.data.success){
            console.log(response.data);
            setCountData(response.data?.stats|| [])
        }
    }

    const pointsPerPage=10

    useEffect(() => {
        allCountsData()
    }, [filterBy])
    

  return (
    <div>
      <AdminNavbar />
   <section className="createBetPage">
        <div className="container">
        <div className="filterRow mb-4">
                <button onClick={(e)=>setFilterBy('day')} className={filterBy == 'day'? 'active':''}>Today</button>
                <button onClick={(e)=>setFilterBy('week')} className={filterBy == 'week'? 'active':''}>Week</button>
                <button onClick={(e)=>setFilterBy('month')} className={filterBy == 'month'? 'active':''}>Month</button>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="statsBox">
                        <div className="title">User Joined</div>
                        <div className="val">{countData.userJoined || 0}</div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="statsBox">
                        <div className="title">Game Played</div>
                        <div className="val">{countData.gamePlayed || 0}</div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="statsBox">
                        <div className="title">Wallet Created</div>
                        <div className="val">{countData.wallets || 0}</div>
                    </div>
                </div>
            </div>
       
        </div>
    </section>

  </div>
  )
}

const TableRow =({item,key,count}) => {
    return(
      <tr key={key}>
          <td>
            {item?.username}
          </td>
          <td>{item?.email}</td>
          <td>{count[item?._id]}</td>
          {/* <td>{item?.createdAt ? (new Date(item?.createdAt).toDateString() +" " + new Date(item?.createdAt).toLocaleTimeString())  : "-"}</td> */}
      </tr>
    )
  }
