import React, { useEffect, useState } from 'react';
import { Navigate, RouterProvider, createBrowserRouter, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Dashboard from './components/dashboard';
import User from './components/user';
import NavbarCustom from './components/navbar';
import SidebarCustom from './components/sidebar';
import Footer from './components/footer';
import { CollapsedContext } from './context';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getTwitterUser, getUserBalance, loginUser, setDiscordUser, 
  getChatNotifications,
  setChatMessageFocus,
  setContextualMessages } from './redux/ActionCreators';
import { useMediaQuery } from 'react-responsive'
import { FootballPage } from './components/football';
import { FootballDetailPage } from './components/football/footballDetail';
import { showToast } from './App';
import { VipPage } from './components/vip';
import Signin from './components/admin/login'
import DiscordFetch from './components/connectDiscord';
import TwitterValidate from './components/connectTwitter';
import { UserPointsPage } from "./components/admin/UserPointsPage";
import { UserJoinPage } from "./components/admin/userJoined";
import { WalletGeneratedPage } from "./components/admin/walletCreated";
import { GamePlayedPage } from "./components/admin/gamePlayed";
import { MostGamePlayed } from "./components/admin/mostPlayedGames";
import { AllCounts } from "./components/admin/allCounts";
import { JackpotBalances } from "./components/admin/jackpotBalances";
import { CreateBet } from "./components/admin/createBet";
import { Faq } from './components/faq/faq';

const Wrapper = ({children}) => {
  const [c, setC] = useState(false);
  const value = { collapsed: c, setCollapsed: setC };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const loc = useLocation();
  const authedUser = useSelector(s => s.authedUser);

	const handleNotificationClick = (notification) => {
    if (notification?.chat?._id) {
      console.log(notification);
      dispatch(setContextualMessages(notification.chat._id));
      dispatch(setChatMessageFocus(notification.chat._id));
    }
  };
  
	useEffect(() => {
    if (authedUser?.authedUser?._id) {
      dispatch(getChatNotifications(authedUser?.authedUser._id));
      handleNotificationClick();
    }
  }, [authedUser?.authedUser]);

  useEffect(() => {
    console.log(loc);
		if (localStorage.getItem('token')){
			fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/validate`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('token')}`
				}
			}).then(r => r.ok ? r.json() : ({success: false}))
			.then(res => {
				if (res.success){
					res.user.token = localStorage.getItem('token')
					dispatch(loginUser(res.user))
          dispatch(getUserBalance(res.user));
          
				}
				else{
					localStorage.removeItem('token');
					navigate('/'+(loc.search ? loc.search : ''));
				}
			})
		}
		else{
			navigate('/'+(loc.search ? loc.search : ''));
		}
	}, []);
  useEffect(() => {
    function updateSize() {
      if(window.innerWidth < 992){
        setC(true)
      }
      console.log(window.innerWidth);
      // setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])
  
  return(
    <CollapsedContext.Provider value={value}>
      <NavbarCustom/>
      <div style={{display: 'flex'}}>
        <SidebarCustom collapsed={c} setCollapsed={setC} />
        <div 
        style={{paddingLeft: c || isTabletOrMobile ? '80px': '330px', transition: 'padding-left 0.3s ease-in-out',width:'100%'}}
        >
          {children}
          <Footer/>
        </div>
      </div>
    </CollapsedContext.Provider>
  );
}

const useAuth = (props) => {
  const user = { loggedIn: false };
  const adminStore = useSelector((state) => state.authedAdmin.authedAdmin);
  console.log(adminStore);
  if (adminStore?.token) {
    user.loggedIn = true;
  }

  return user && user.loggedIn;
};

const AdminWrapper = ({children}) => {
  const isAuth = useAuth();
  
  return isAuth ? <>{children}</> : <Navigate to="/admin-login" />;
}

const router = createBrowserRouter([
  { path: "/", element: <Wrapper><Dashboard /></Wrapper> },
  { path: "/user", element: <Wrapper><User /></Wrapper> },
  { path: "/vip", element: <Wrapper><VipPage /></Wrapper> },
  { path: "/faq", element: <Wrapper><Faq /></Wrapper> },
  {path: "/football", element: <Wrapper><FootballPage /></Wrapper>},
  {path: "/football/:id", element: <Wrapper><FootballDetailPage /></Wrapper>},
  {path: "/discord/", element: <DiscordFetch />},
  {path: "/twitterValidate/", element: <TwitterValidate />},
  {path: "/admin-login", element: <Signin />},
  {path: "/create-bet", element: <AdminWrapper><CreateBet /></AdminWrapper>},
  {path: "/user-points", element: <AdminWrapper><UserPointsPage /></AdminWrapper>},
  {path: "/user-joined", element: <AdminWrapper><UserJoinPage /></AdminWrapper>},
  {path: "/wallet-generated", element: <AdminWrapper><WalletGeneratedPage /></AdminWrapper>},
  {path: "/played-game", element: <AdminWrapper><GamePlayedPage /></AdminWrapper>},
  {path: "/most-played-game", element: <AdminWrapper><MostGamePlayed /></AdminWrapper>},
  {path: "/all-counts", element: <AdminWrapper><AllCounts /></AdminWrapper>},
  {path: "/jackpot-balances", element: <AdminWrapper><JackpotBalances /></AdminWrapper>}

]);

export default function Main(){
  
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}