import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AdminNavbar } from './adminNavbar';

export const GamePlayedPage = () => {
    const [gamePlayed,setGamePlayed] = useState([])
    const [PageNum,setPageNum] = useState(1)
    const [filterBy,setFilterBy] = useState('day')

    const getJoinedUser = async() => {
        const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stats/games-played/${filterBy}`;
        let response = await axios.get(url,
            {headers: {
                'Authorization': localStorage.getItem('admin')
            }}
        );

        if(response.data.success){
            console.log(response.data);
            setGamePlayed(response.data?.stats|| [])
        }
    }

    const pointsPerPage=10

    useEffect(() => {
        getJoinedUser()
    }, [filterBy])
    

  return (
    <div>
      <AdminNavbar />
   <section className="createBetPage">
        <div className="container-fluid">
            <div className="filterRow">
                <button onClick={(e)=>setFilterBy('day')} className={filterBy == 'day'? 'active':''}>Today</button>
                <button onClick={(e)=>setFilterBy('week')} className={filterBy == 'week'? 'active':''}>Week</button>
                <button onClick={(e)=>setFilterBy('month')} className={filterBy == 'month'? 'active':''}>Month</button>
            </div>
          <div className="table-responsive mt-4 homeTable">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th scope="col">Username</th>
                  <th scope="col">email</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                  {gamePlayed.slice(0, pointsPerPage*PageNum) .map((item,key)=>(
                  <TableRow item={item} key={key} />
                  ))}
              
              </tbody>
            </table>
          </div>
          {gamePlayed.length > pointsPerPage*PageNum  &&
          <div className="createBetBtn text-center">
              <button className="themeBtn" onClick={()=>setPageNum(PageNum+1)}>Show More</button>
          </div>
          }
        </div>
    </section>

  </div>
  )
}

const TableRow =({item,key}) => {
    return(
      <tr key={key}>
          <td>
            {item?.username}
          </td>
          <td>{item?.email}</td>
          <td>{item?.createdAt ? (new Date(item?.createdAt).toDateString() +" " + new Date(item?.createdAt).toLocaleTimeString())  : "-"}</td>
      </tr>
    )
  }
