import React from "react";
import { Modal } from 'react-bootstrap'

export default function CreatePoolModal({show, handleClose, createPool, createPoolRef}){
  return(
    <Modal id="WalletModal" className="WalletModal predModal" show={show} onHide={handleClose} centered >
      <Modal.Body>
          <div className='betOptionsModal'>
              <h1>Create Pool</h1>
              <div className='mt-3 mb-3'>
                  <label className='text-white'>Points per entry:</label><br/>
                  <input type='number' ref={createPoolRef} />
              </div>
              <button className="continue" onClick={createPool}>Create</button>
          </div>
      </Modal.Body>
    </Modal>
  );
}