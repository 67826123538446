import React from "react";
import { Col, Row } from "react-bootstrap";
import VIPText from '../../imgs/vip-text.png';
import vipBeeIco from '../../imgs/vipBeeIco.png';
import { useNavigate } from "react-router-dom";

export default function VIPSection(){
  const navigate = useNavigate();
  return(
    // <Row>
    //   <Col className="vip-banner" xs='12'>
    //     <img src={VIPText} />
    //     <button className="theme-btn">Join Now!</button>
    //   </Col>
    // </Row>
    <section className="vipSec">
    <div className="vipRow">
      <div className="col1 animatable slideInUp">
        <img className='icon' src={vipBeeIco} alt="" />
        <div>
          <div className="vip">VIP</div>
          <div className="text">LOUNGE AREA</div>
        </div>
      </div>
      <div className="bar"></div>
      <div className="col2 animatable slideInUp animationDelay200">
        <div className="unlimited">Unlimited Fun for VIP Members!</div>
        <div className="vip"><span>VIP</span> Members!</div>
      </div>
    </div>
      <div className="text-center">
        <button className="themeBtn" onClick={()=>navigate('/vip')} >Join Now!</button>
      </div>
  </section>
  );
}