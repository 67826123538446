import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AdminNavbar } from './adminNavbar';

let help = {
  diceWeeklyBalance: 'Dice Weekly Jackpot',
  rpsWeeklyBalance: 'RPS Weekly Jackpot',
  connectWeeklyBalance: 'Connect 4 Weekly Jackpot',
  diceDailyBalance: 'Dice Daily Jackpot',
  rpsDailyBalance: 'RPS Daily Jackpot',
  connectDailyBalance: 'Connect 4 Daily Jackpot',
}

export const JackpotBalances = () => {
    const [jackpotBalances, setJackpotBalances] = useState([]);

    const getJackpotBalances = async() => {
        const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users/jackpot-balance`;
        let response = await axios.get(url);

        if(response.data.success){
          setJackpotBalances(Object.keys(help).map(k => ({
            key: k,
            name: help[k],
            points: response.data[k]
          })));
        }
    }

  useEffect(() => {
    getJackpotBalances()
  }, [])
    
  return (
    <div>
      <AdminNavbar />
      <section className="createBetPage">
        <div className="container-fluid">
          <div className="table-responsive mt-4 homeTable">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th scope="col">Jackpot</th>
                  <th scope="col">Points</th>
                </tr>
              </thead>
              <tbody>
                  {jackpotBalances.map((item,key)=>(
                  <TableRow item={item} key={key} />
                  ))}
              
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  )
}

const TableRow =({item,key}) => {
    return(
      <tr key={key}>
          <td>
            {item?.name}
          </td>
          <td>{item?.points}</td>
      </tr>
    )
  }
