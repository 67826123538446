import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from '../../imgs/logo.svg';
import search from '../../imgs/Search.svg';
import filter from '../../imgs/Filter.svg';
import {ReactComponent as Twitter} from '../../imgs/twitter.svg';
import {ReactComponent as Telegram} from '../../imgs/telegram.svg';
import {ReactComponent as Social} from '../../imgs/social.svg';
import beeicon from '../../imgs/navbar-balance-logo.svg';
import DepositIcon from '../../imgs/navbar-deposit-logo.svg';
import notifIcon from '../../imgs/navbar-notification.svg';
import chatIcon from '../../imgs/navbar-chat.svg';
import './index.css';
import { useSelector } from "react-redux";
import DepositModal from "./depositModal";
import { Chat } from "./chat";

export default function NavbarCustom(){
  const authedUser = useSelector(state => state.authedUser);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [chatToggle, setChatToggle] = useState(false);

  useEffect(() => {
    document.getElementsByClassName('navbar-toggler')[0].addEventListener('click', () => {
      if (document.getElementsByClassName('navbar-toggler')[0].classList.contains('collapsed')){
        document.getElementById('sidebar').classList.add('navcollapsed');
      }
      else{
        document.getElementById('sidebar').classList.remove('navcollapsed');
      }
    });
    return () => {
      document.getElementsByClassName('navbar-toggler')[0].removeEventListener('click', () => {
        if (document.getElementsByClassName('navbar-toggler')[0].classList.contains('collapsed')){
          document.getElementById('sidebar').classList.add('navcollapsed');
        }
        else{
          document.getElementById('sidebar').classList.remove('navcollapsed');
        }
      });
    }
  }, []);

  return(
    <Navbar expand="xl" id="navbar-main" className="bg-body-tertiary navbar-dark">
      <Container fluid>
        <Navbar.Brand href="#home">
          <img
            src={logo}
            style={{height: '55px', marginRight: '25px'}}
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse style={{justifyContent: Object.keys(authedUser.authedUser).length > 0 ? 'space-between' : 'end'}} id="basic-navbar-nav collapse-btn">
        <Nav.Item className="ml-5 search-box">
            <img src={filter} className="search-right" />
            <img src={search} className="search-left" />
            <input id="nav-input" placeholder="Search" />
        </Nav.Item>
          <div className={Object.keys(authedUser.authedUser).length > 0 ?"socialRow me-auto":"socialRow ms-auto"} >
            <a href="https://twitter.com/BeeCasinoGames" target="_blank" rel="noopener noreferrer"><Twitter /> </a>
            <a href="https://t.me/BeeCasinoGames" target="_blank" rel="noopener noreferrer"><Telegram /> </a>
            <a href="http://" target="_blank" rel="noopener noreferrer"><Social /> </a>
          </div>
          
          <div className="nav-desposit-row" style={Object.keys(authedUser.authedUser).length > 0 ? {display: 'flex'} : {'display': 'none'}}>
            <Nav className="mx-3">
              <Nav.Item className="mx-2 theme-btn" style={{backgroundColor: 'black', color: 'white',  borderRadius: '12px', cursor: 'pointer', paddingLeft: '15px', paddingRight: '15px'}}>
                <img src={beeicon} style={{marginRight: '8px'}} /> {authedUser.authedUser.points} Honey Points
              </Nav.Item>
              <Nav.Item onClick={() => setShowDepositModal(true)} className="mx-2 theme-btn" style={{fontFamily: 'GothamBold', borderRadius: '12px', cursor: 'pointer', paddingLeft: '15px', paddingRight: '15px'}}>
                <img src={DepositIcon} style={{marginRight: '8px'}} /> Deposit
              </Nav.Item>
            </Nav>
            <Nav className="nav-msg" style={{alignItems: 'center'}}>
              <Nav.Item className="mx-2" style={{cursor: 'pointer'}} onClick={()=>setChatToggle(!chatToggle)} ><img src={chatIcon} /></Nav.Item>
              <Nav.Item className="mx-2" style={{cursor: 'pointer'}}><img src={notifIcon} /></Nav.Item>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
      <DepositModal show={showDepositModal} setShow={setShowDepositModal} />
      <Chat chatToggle={chatToggle} /> 
    </Navbar>
  );
}