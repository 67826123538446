import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AdminNavbar } from './adminNavbar';

export const MostGamePlayed = () => {
    const [gamePlayed,setGamePlayed] = useState([])
    const [PageNum,setPageNum] = useState(1)
    const [filterBy,setFilterBy] = useState('day')
    const [count,setCount] = useState({})

    const getMostGamePlayed = async() => {
        const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stats/most-games-played`;
        let response = await axios.get(url,
            {headers: {
                'Authorization': localStorage.getItem('admin')
            }}
        );

        if(response.data.success){
            console.log(response.data);
            setGamePlayed(response.data?.stats|| [])
            setCount(response.data?.count || {})
        }
    }

    const pointsPerPage=10

    useEffect(() => {
        getMostGamePlayed()
    }, [filterBy])
    

  return (
    <div>
      <AdminNavbar />
   <section className="createBetPage">
        <div className="container-fluid">
            
          <div className="table-responsive mt-4 homeTable">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th scope="col">Username</th>
                  <th scope="col">Email</th>
                  <th scope="col">No. of played</th>
                </tr>
              </thead>
              <tbody>
                  {gamePlayed.map((item,key)=>(
                      <TableRow item={item} key={key} count={count} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </section>

  </div>
  )
}

const TableRow =({item,key,count}) => {
    return(
      <tr key={key}>
          <td>
            {item?.username}
          </td>
          <td>{item?.email}</td>
          <td>{count[item?._id]}</td>
          {/* <td>{item?.createdAt ? (new Date(item?.createdAt).toDateString() +" " + new Date(item?.createdAt).toLocaleTimeString())  : "-"}</td> */}
      </tr>
    )
  }
