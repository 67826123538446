import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { getTwitterUser } from "../../redux/ActionCreators";
import { showToast } from "../../App";

export default function TwitterValidate () {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
	let flag = false;
  const getTwitterUsername = async (code, username) => {
    try{
      dispatch(getTwitterUser(code, username)).then(res => {
				console.log(res, res?.type === "LOGGED_IN");
        if (res?.type === "LOGGED_IN" || res?.type === "UPDATE_USER") {
          navigate(`/user`, {state: {tab: 'settings'}});
        } else {
          navigate("/");
					showToast("Failed to connect twitter");
        }
			});
    }
    catch(e){
      console.log(e);
      navigate(`/user`);
    }
  }

  useEffect(() => {
			let code = searchParams.get('code');
			let state = searchParams.get('state');
			let error = searchParams.get('error');
			console.log(state, error, code);
			if (state){
				let username = state.split('-')[0];
				if (code && !!localStorage.getItem('token')){
					getTwitterUsername(code, username);
				}
				else if (error){
					//{ state: { err_message: `Failed to fetch: ${error}` }}
					console.log(error);
					alert(`Failed: ${error}`);
					navigate(`/`);
				}
			}
			else{
				console.log("nostate");
				showToast(`Failed: Empty Parameters`);
				navigate(`/`);
			}
  }, []);
  
  if (searchParams.get("code") && !!localStorage.getItem('token')) {
    return (
      // <Navigate to={`/profile/${searchParams.get('state')}`} />
      <></>
    );
  } else {
    return <Navigate to="/" />;
  }
}
