import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { showToast } from '../../App';

export default function GameRow({game, setGames}){
  let [pools, setPools] = useState([]);
  let [teamAGoals, setTeamAGoals] = useState(0);
  let [teamBGoals, setTeamBGoals] = useState(0);
  let [modalShow, setModalShow] = useState(false);
  let [resolveModalShow, setResolveModalShow] = useState(false);
  
  const getDetail = async () => {
    let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/get-pools/${game._id}`, {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('admin'),
      }
    }).then(r => r.ok ? r.json() : {});
    if (res.success){
      setPools(res.pools.map((p, i) => {p.entries = res.entriesArray[i]; return p;}));
    }
  }

  const resolveGame = async () => {
    let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/resolve-pools`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ game_id: game._id, goalA: `goals_${teamAGoals}`, goalB: `goals_${teamBGoals}` })
    }).then(r => r.ok ? r.json() : {success: false, msg: 'Failed'});
    if (res.success){
      setResolveModalShow(false);
      setGames((games) => games.map(g => g._id === res.game._id ? res.game : g));
      showToast('Success');
    }
    else{
      showToast(res.msg);
    }
  }
  
  useEffect(() => {
    getDetail();
  }, []);

  return(
    <>
      <tr key={game._id}>
        <td>{game.teamA.name}</td>
        <td>{game.teamB.name}</td>
        <td>{pools.length}</td>
        <td><button onClick={() => setModalShow(true)}>View Details</button></td>
        <td>{game?.ended ? 'Resolved' : <button onClick={() => setResolveModalShow(true)}>Resolve</button>}</td>
      </tr>
      <Modal
          show={modalShow}
          onHide={() => setModalShow(false)} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
        <Modal.Body>
          <div className="betModal">
            <div className="closeIcon" onClick={() => setModalShow(false)}>
              <i className="fas fa-times"></i>
            </div>
            <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
              <h2 className="text-center">Game Details </h2>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <h4>Total Pools</h4>
                  <div className='text-white'>{pools.length}</div>
                </div>
                {pools.map((pool, i) => (
                  <PoolDetailSection i={i+1} game={game} pool={pool} key={pool._id} />
                ))}
            </form>
          </div>
        </Modal.Body>
      </Modal>
      
      <Modal
        show={resolveModalShow}
        onHide={() => setResolveModalShow(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body>
        <div className="betModal">
            <div className="closeIcon" onClick={() => setResolveModalShow(false)}>
            <i className="fas fa-times"></i>
            </div>
          <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
            <h2 className="text-center">Resolve Game  </h2>
              {/* <div className="form-group">
                <input
                  onChange={onInputChange}
                  name="betType"
                  className="text"
                  placeholder="betType"
                />
              </div> */}
              <div className="form-group">
                <label>Goals of {game?.teamA?.name} (Team A)</label><br/>
                <input
                  className="text"
                  onChange={(e) => setTeamAGoals(e.target.value)}
                  type="number"
                  placeholder={`Goals of ${game?.teamA?.name} (Team A)`}
                  step="any"
                  value={teamAGoals}
                />
              </div>
              <div className="form-group">
              <label>Goals of {game?.teamB?.name} (Team B)</label><br/>
                <input
                  className="text"
                  onChange={(e) => setTeamBGoals(e.target.value)}
                  value={teamBGoals}
                  type="number"
                  placeholder={`Goals of ${game?.teamB?.name} (Team B)`}
                />
              </div>
              <div className="text-center m-signin-submit">
                <button onClick={resolveGame} className="btn">
                  Resolve
                </button>
              </div>

          </form>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const PoolDetailSection = ({pool, game, i}) => {
  return(
    <>
      <hr/>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Pool # {i}</h4>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Pool Type</h4>
        <div className='text-white'>{pool.type}</div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Points per Entry</h4>
        <div className='text-white'>{pool.points}</div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Total Entries</h4>
        <div className='text-white'>{pool.entries.length}</div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Entries for {game.teamA?.name}:</h4>
        <div className='text-white'>{pool.entries.filter(e => e.result === game.teamA?._id).length}</div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Entries for {game.teamB?.name}:</h4>
        <div className='text-white'>{pool.entries.filter(e => e.result === game.teamB?._id).length}</div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Entries for Draw:</h4>
        <div className='text-white'>{pool.entries.filter(e => !e.result).length}</div>
      </div>
    </>
  );
}