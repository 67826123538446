import React from "react";
import { Col, Row } from "react-bootstrap";
import StatsDetails from "./statsDetails";
import LeaderboardSection from "./leaderboardSection";
import JackpotSection from "./jackpotSection";
import PaymentHistorySection from "./paymentHistory";

export default function StatsSection(){
  return(
    <Row className="mt-5 px-3">
      <Col xs='12'>
        <StatsDetails />
        <LeaderboardSection />
        <JackpotSection />
        <PaymentHistorySection />
      </Col>
    </Row>
  );
}