import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import LeaderboardCup from '../../imgs/leaderboard-cup.svg';
import LeaderboardDDBtn from '../../imgs/leaderboard-dd-btn.svg';
import LeaderboardPfp from '../../imgs/leaderboard-pfp.png';
import LeaderboardTotalPts from '../../imgs/leaderboard-total-pts.svg'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const keys = {
  'purchase': 'Purchase Points Tx',
  'redeem': 'Redeem Points Tx',
  'withdraw': 'Withdraw Tx',
}
export default function PaymentHistorySection(){
  console.log(keys, keys['purchase']);
  let [txType, setTxType] = useState('purchase');
  // let [time, setTime] = useState('weekly');
  let [withdrawTx, setWithdrawTx] = useState([]);
  let [purchasePtsTx, setPurchasePtsTx] = useState([]);
  let [redeemPtsTx, setRedeemPtsTx] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/payment-history`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(r => r.json())
    .then(r=>{
      if (r.success){
        setWithdrawTx(r.withdrawTx);
        setPurchasePtsTx(r.purchasePtsTx);
        setRedeemPtsTx(r.redeemPtsTx);
      }
    })
  }, []);

  return(
    <Row className="mt-4" style={{border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '19px'}}>
      <Col xs='12' className="leaderboard-header" style={{border: '1px solid rgb(251, 176, 64, 0.3)', borderRadius: '19px'}}>
        <div className="leaderboard-header-left">
          <img src={LeaderboardCup} />
          <div className="leaderboard-header-text">
            <span className="leaderboard-text">Payment</span>
            <span className="leaderboard-rank-text">History</span>
          </div>
        </div>
        <Dropdown className="leaderboard-dd">
            <Dropdown.Toggle id="leaderbaord-dropdown-basic">
              {keys[txType]}
              <img src={LeaderboardDDBtn} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {Object.keys(keys).map(k => (
                <Dropdown.Item onClick={() => setTxType(k)} >{keys[k]}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
      </Col>
      <Col xs='12'>
        <Row>
          <Col xs='12' className="p-4">
            <div className="leaderboard-pre-table">
              <div style={{fontSize: '22px'}}>{keys[txType]} Jackpot</div>
              {/* <div style={{padding: '12px 0px', fontFamily: 'GothamLight', fontSize: '15px', color: '#47464A', borderRadius: '12px', backgroundColor: '#000000', border: '1px solid #202020',}}>
                <span className={time === 'daily' ? 'active' : ''}onClick={() => setTime('daily')} style={{cursor: 'pointer', padding: '0px 20px'}}>Daily</span>
                <span className={time === 'weekly' ? 'active' : ''}onClick={() => setTime('weekly')} style={{cursor: 'pointer', padding: '0px 20px'}}>Weekly</span>
              </div> */}
            </div>
            <table style={{width: "100%", color: 'white', borderCollapse: 'separate', borderSpacing: '10px'}}>
            {(txType === 'purchase' || txType === 'redeem') ? (
              <colgroup>
                <col span="1" style={{width: "5%"}}/>
                <col span="1" style={{width: "50%"}}/>
                <col span="1" style={{width: "15%"}}/>
                <col span="1" style={{width: "15%"}}/>
                <col span="1" style={{width: "15%"}}/>
              </colgroup>
            ) : (<></>)}
            {(txType === 'withdraw') ? (
              <colgroup>
                <col span="1" style={{width: "5%"}}/>
                <col span="1" style={{width: "35%"}}/>
                <col span="1" style={{width: "30%"}}/>
                <col span="1" style={{width: "10%"}}/>
                <col span="1" style={{width: "10%"}}/>
                <col span="1" style={{width: "10%"}}/>
              </colgroup>
            ) : (<></>)}  
              <thead>
                {(txType === 'purchase' || txType === 'redeem') ? (
                  <tr>
                    <th>#</th>
                    <th>Tx Hash</th>
                    <th>Chain</th>
                    <th>Time</th>
                    <th>{txType === 'purchase' ? 'Points Bought' : ''}{txType === 'redeem' ? 'Points Redeemed' : ''}</th>
                  </tr>
                ):(<></>)}
                {txType === 'withdraw' ? (
                  <tr>
                    <th>#</th>
                    <th>Tx Hash</th>
                    <th>Withdraw Address</th>
                    <th>Amount Withdraw</th>
                    <th>Chain</th>
                    <th>Time</th>
                  </tr>
                ) : (<></>)}
              </thead>
              <tbody>
                {txType === 'purchase' ? (purchasePtsTx.map((purchaseTx, i) => (
                  <tr key={`${i}-${purchaseTx._id}`}>
                    <td>{i+1}</td>
                    <td onClick={() => window.open(purchaseTx.chain === 'ZK' ? `https://explorer.zksync.io/tx/${purchaseTx.txHash}` : `https://basescan.org/tx/${purchaseTx.txHash}`,"_blank")} style={{cursor: 'pointer', textDecoration: 'underline', fontStyle: 'italic'}}>{purchaseTx.txHash.substring(0,8)}...{purchaseTx.txHash.substring(purchaseTx.txHash.length -8,purchaseTx.txHash.length)}</td>
                    <td>{purchaseTx.chain}</td>
                    <td>{months[(new Date(purchaseTx.createdAt)).getMonth()]} {(new Date(purchaseTx.createdAt)).getDate().toString().padStart(2, '0')}, {(new Date(purchaseTx.createdAt)).getFullYear()}</td>
                    <td>{purchaseTx.points}</td>
                  </tr>
                ))) : (<></>)}
                {txType === 'redeem' ? (redeemPtsTx.map((redeemPt, i) => (
                  <tr key={`${i}-${redeemPt._id}`}>
                    <td>{i+1}</td>
                    <td onClick={() => window.open(redeemPt.chain === 'ZK' ? `https://explorer.zksync.io/tx/${redeemPt.txHash}` : `https://basescan.org/tx/${redeemPt.txHash}`,"_blank")}  style={{cursor: 'pointer', textDecoration: 'underline', fontStyle: 'italic'}}>{redeemPt.txHash.substring(0,8)}...{redeemPt.txHash.substring(redeemPt.txHash.length -8,redeemPt.txHash.length)}</td>
                    <td>{redeemPt.chain}</td>
                    <td>{months[(new Date(redeemPt.createdAt)).getMonth()]} {(new Date(redeemPt.createdAt)).getDate().toString().padStart(2, '0')}, {(new Date(redeemPt.createdAt)).getFullYear()}</td>
                    <td>{redeemPt.points*-1}</td>
                  </tr>
                ))) : (<></>)}
                {txType === 'withdraw' ? (withdrawTx.map((wTx, i) => (
                  <tr key={`${i}-${wTx._id}`}>
                    <td>{i+1}</td>
                    <td onClick={() => window.open(wTx.chain === 'ZK' ? `https://explorer.zksync.io/tx/${wTx.txHash}` : `https://basescan.org/tx/${wTx.txHash}`,"_blank")} style={{cursor: 'pointer', textDecoration: 'underline', fontStyle: 'italic'}}>{wTx.txHash.substring(0,8)}...{wTx.txHash.substring(wTx.txHash.length -8,wTx.txHash.length)}</td>
                    <td>{wTx.address}</td>
                    <td>{(wTx.amount).substring(0,8)}</td>
                    <td>{wTx.chain}</td>
                    <td>{months[(new Date(wTx.createdAt)).getMonth()]} {(new Date(wTx.createdAt)).getDate().toString().padStart(2, '0')}, {(new Date(wTx.createdAt)).getFullYear()}</td>
                  </tr>
                ))) : (<></>)}
                {/* {stats.map((stat, i) => {
                  return(
                    <tr key={`${i}-${stat._id._id}-${gameName}-${time}`}>
                      <td>{i+1}</td>
                      <td>
                        <img style={{width: '35px', minWidth: '35px', marginRight: '10px', height: '35px', borderRadius: '50%'}} src={stat.user_id.profilepic ? `${process.env.REACT_APP_SERVER_BASE_URL}/images/${stat.user_id.profilepic}` : LeaderboardPfp}  />
                        {stat.user_id.username}
                      </td>
                      <td></td>
                      <td>
                        <img style={{marginRight: '10px'}} src={LeaderboardTotalPts}  />
                        {parseFloat(stat.amount).toFixed(3)}
                      </td>
                      <td>{months[(new Date(stat.createdAt)).getMonth()]} {(new Date(stat.createdAt)).getDate().toString().padStart(2, '0')}, {(new Date(stat.createdAt)).getFullYear()}</td>
                    </tr>
                  );
                })} */}
              </tbody>
            </table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}