import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";

// export default function GameCard({ i, game }){
//   return(
//     <Col className={i===0 ? "" : "p-0"}>
//       <div className="game-img-div">
//         <img className="game-img" src={game.img} />
//       </div>
//       <div className="game-name-div">
//         <div className="name">{game.name}</div>
//         <div className="players">{game.players} Players</div>
//         {game.pointsEarned ? (
//           <span className="points-earned">{game.pointsEarned} {game.pointsEarned !== 'You Never Played' ? 'Honey Points Earned' : ''}</span>
//         ) : (<></>)}
//       </div>
//     </Col>
//   );
// }

export default function GameCard({i,game}) {
  const authedUser = useSelector(s => s.authedUser);
  const [pointsEarned, setPointsEarned] = useState(0);
  useEffect(() => {
    if (game?.localName){
      if (Object.keys(authedUser?.authedUser).length > 0){
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/points-earned/${game?.localName}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(r => r.json())
        .then(res => {
          if (res?.success){
            setPointsEarned(parseFloat(res?.pointsEarned));
          }
        });
      }
    }
  }, [authedUser?.authedUser]);

  return (
    <div className={`gameCard animatable slideInUp animationDelay${i+1}00`}>
    <div className="imgDiv">
      <img className={game.animation ?'w-100 cardImg':'w-100'} src={game.img} alt="" />
      <img className='icon' src={game.icon} alt="" />
      {game?.link && 
       <a href={game.link} className="themeBtn" >Play Now</a>
       }
    </div>
    <div className="name">{game?.name}</div>
    <div className="players">{game?.players} Players</div>
    {(game?.localName && authedUser?.authedUser?._id) ? (
       <div className="badge">
        {/* {game.pointsEarned} {game.pointsEarned !== 'You Never Played' ? 'Honey Points Earned' : ''} */}
        {pointsEarned > 0 ? `${pointsEarned.toFixed(2)} Honey Points Earned` : `You Never Played`}
      </div>
     ) : (<></>)}
  </div>
  )
}