import React from "react";
import tournamentOrganizer from '../../imgs/tournament-arranged.png'
import clock from '../../imgs/clock.svg';
import Timer from "./timer";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../App";
import { getUserBalance } from "../../redux/ActionCreators";

export default function TournamentCard({tournament,i,updateTournament}){
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();
  const joinTournament = async () => {
		if (Object.keys(authedUser.authedUser).length > 0){
			if (authedUser.authedUser.points > 50){
				fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/tournaments/join`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${localStorage.getItem('token')}`
					},
					body: JSON.stringify({tournament: tournament._id})
				}).then(r => r.ok ? r.json() : ({success: false, msg: 'Failed'})).then(r => {
					if (r.success){
            updateTournament(r.tournament);
						showToast('Success');
            dispatch(getUserBalance(authedUser.authedUser));
					}
					else{
						showToast(r.msg);
					}
				})
			}
			else{
				showToast('Minimum 50 points are required to join tournament');
			}
		}
		else{
			showToast('Connect Wallet');
		}
	}
	
  return(
    <div className={`tournament-card`} style={{borderColor: tournament.bgColor}}>
      <div style={{ position: 'relative' }}>
        <img style={{width: '100%', height: '100%', borderRadius: '16px'}} src={tournament.img} />
        <div style={{position: 'absolute', borderRadius: '0px 0px 16px 16px', backdropFilter: 'blur(7px)', width: '100%', bottom: 0, textAlign: 'center'}}>
          <img className="img-fluid" style={{marginBottom: '10px'}} src={tournamentOrganizer} />
        </div>
      </div>
      <div className="mt-3" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className="font-gotham-medium" style={{color: 'white'}}>
          <img  src={tournament.tournamentImg} style={{height: '30px'}} /> {tournament.name}
        </div>
        <div className="font-gotham-light" style={{ fontSize: '10px'}}>Arcade
        <br/>
         Games</div>
      </div>
      <div className="mt-3" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{fontFamily: 'GothamLight', fontWeight: 600, fontSize: '12px', color: 'rgba(255,255,255,0.6)'}}><img height={'20px'} src={clock} /> {Math.ceil((new Date(tournament.start_time) - new Date())/(1000*60*60*24))} days left</div>        
        <div className="font-gotham-bold" style={{color: '#FBB040', fontSize: '14px'}}>{tournament?.start_time ? <Timer deadline={tournament.start_time} /> : <></>}</div>
      </div>
      <div className="mt-3" onClick={joinTournament} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <button className={tournament.btnClass} style={{width: '100%', borderRadius: '10px'}}>{tournament.btnText}</button>
      </div>
      
      <div className="mt-3" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{fontFamily: 'GothamLight', fontWeight: 600, fontSize: '12px', color: 'rgba(255,255,255,0.6)'}}>
          <span>Entry:</span> {' '} 
          <span style={{color: 'white'}}>50</span>   {' '}
          <span style={{color: '#FBB040', fontSize: '10px'}}>Honey Points</span>  
        </div>
        <div className="font-gotham-bold" style={{color: '#FFFFFF99', fontSize: '14px'}}>{tournament.participants.length}/{process.env.REACT_APP_TOTAL_PARTICIPANTS}</div>
      </div>
    </div>
  );
}