
import logo from "../imgs/logo_beecasino.png";

export function Loader({loader,msg}) {
    return(
        <>
            {loader &&
                <div className="beeLoader">
                    <div className="loader">
                        <img src={logo} alt="loader" />
                        {!msg &&
                        <div class="loading__bar"></div>
                        }
                        {msg == "Awaiting the outcome" &&
                        <div class="spinLoading spinBar">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        }
                        <h3 className="text-center mt-3 mb-3 px-2" >
                            {msg? msg:"Loading..."}
                        </h3>
                    </div>
                </div>
                }
        </>
    )
}