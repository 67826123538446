import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { showToast } from "../../App";
import { useDispatch } from "react-redux";
import { getUserBalance, loginUser, updateUser } from '../../redux/ActionCreators';
import showPassImg from '../../imgs/showPass.svg';
import hidePass from '../../imgs/hidePass.svg';
import logo from '../../imgs/logo.svg';
import google from '../../imgs/google.png';
import twitter from '../../imgs/twitter.png';

export default function Login({setSignupModal,setShowLogin}){
  const [showLoginModal, setShowLoginModal] = useState(false);
	const [forgetPassModal, setForgetPassModal] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch();

	const handleShowForgetModal =()=>{
		setShowLoginModal(false)
		setForgetPassModal(true)
	}
	const login = async () => {
		let email = document.getElementById('sign-in-email').value;
		let pwd = document.getElementById('sign-in-pwd').value;
		var expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		if (expression.test(email)){
			fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email: email, pwd: pwd })
			}).then(r => r.json())
			.then(res => {
				if (res.success){
					res.user.token = res.tok;
					dispatch(loginUser(res.user));
					dispatch(getUserBalance(res.user));
          setShowLoginModal(false)
				}
				else{
					showToast(res.msg);
				}
			})			
		}
		else{
			showToast('Invalid email address');
		}
	}

	const submitForgetPass =()=>{
		let email = document.getElementById('forget-email').value;
		var expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		if (expression.test(email)){
			console.log(email);
			fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/forget`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email: email })
			}).then(r => r.json())
			.then(res => {
				if (res.success){
          
					setForgetPassModal(false);
					showToast("Please check your mail to reset Password")
					// setLoaderMsg("Please check your mail to reset Password")
					// setLoading(true)
					// setTimeout(() => {
					// 	setLoading(false)
					// 	setLoaderMsg("")
					// 	setForgetPassModal(false)
					// }, 3000);
				}
				else{
					showToast(res.message);
				}
			})			
		}
		else{
			showToast('Invalid email address');
		}
	}
  return(
    <>
		<div >	
			<div >
				<div className="inputMain">
							<i className="fa-solid fa-envelope ico" />
							<input type="text" id="sign-in-email" style={{color: 'white'}} placeholder="Email..." />
				</div>
				<div className="inputMain">
							<i class="fa-solid fa-lock ico"></i>
							<div className="passInput w-100">
								<input type={showPass?"text":"password"} style={{color: 'white'}} id="sign-in-pwd" placeholder="Password..." />
								{showPass?
								<img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
								:
								<img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
								}
							</div>
				</div>
				<div className="mb-2">
							<label onClick={()=>handleShowForgetModal()} className="forget-pass cursor-pointer" >Forget Password</label>
				</div>
				<div className="btnDiv my-3">
							<button className="themeBtn w-100" onClick={login}>
								Login
							</button>
				</div>
				<div className="or">
								<span></span>
								<div className="px-2">Or</div>
								<span></span>
				</div>
				<div className="inputMain">
							<img src={google} alt="" className="ico me-3" />
							<div className="text-center w-100">
								Continue with Google
							</div>
				</div>
				<div className="inputMain">
							<img src={twitter} alt="" className="ico me-3" />
							<div className="text-center w-100">
								Continue with X
							</div>
				</div>
				<div className="already">Dont have an account? <span onClick={()=>setShowLogin(false)}>Sign Up</span></div>
			</div>
			<div className="closeIcon" onClick={() => setSignupModal(false)}>
			<i class="fas fa-times"></i>
			</div>
		</div>
			   
      
			<Modal id="WalletModal" className="signModal" show={forgetPassModal} onHide={()=> setForgetPassModal(false)} centered >
				<Modal.Body>
					<div >
					<div className="logo"><img className="img-fluid" src={logo} alt="" /></div>
					<div >
						<div className="inputMain">
							<i className="fa-solid fa-envelope ico" />
							<input type="email" id="forget-email" placeholder="Email..." />
						</div>
						<div className="btnDiv mt-4">
							<button className="themeBtn w-100" onClick={submitForgetPass}>
								Submit
							</button>
						</div>
					</div>
					<div className="closeIcon" onClick={() => setForgetPassModal(false)}>
					<i class="fas fa-times"></i>
					</div>
					</div>
				</Modal.Body>
			</Modal>
    </>
  );
}