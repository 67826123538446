import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import TournamentRow from "./TournamentRow";
import { showToast } from "../../App";


export default function TournamentSection({}){
  const [tournaments, setTournaments] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [img, setImg] = useState("");
  const authedAdmin = useSelector(s => s.authedAdmin);

  const getTournaments = () => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/tournaments`, {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
    }).then(r => r. ok ? r.json() : {tournaments: []}).then(r => setTournaments(r.tournaments));
  }

  useEffect(() => {
    getTournaments();
  }, []);

  const createTournament = async () => {
    if (Object.keys(authedAdmin.authedAdmin) === 0){
      showToast('Please login'); return;
    }
    let t_game = document.getElementById('t-game').value;
    let t_name = document.getElementById('t-name').value;
    let t_start = document.getElementById('t-start-date').value;
    let t_end = document.getElementById('t-end-date').value;
    console.log(t_end, t_start);
    if (!t_game){
      showToast('Please select a tournament game');
      return;
    }
    if (!t_name){
      showToast('Please enter tournament name');
      return;
    }
    if (!t_start){
      showToast('Please select a tournament end date');
      return;
    }
    if (!t_end){
      showToast('Please select a tournament end date');
      return;
    }
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/tournaments`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({tournament_game: t_game, name: t_name, start_time: t_start, end_time: t_end})
    }).then(r => {
      if (r.ok){return r.json()}else{
        return {success: false, msg: 'Failed'};
      }
    }).then(res => {
      if (res.success){
        setTournaments(tournaments.concat([res.tournament]));
        showToast('Success');
      }
      else{
        showToast(res.msg);
      }
    });
    setModalShow(false);
  }
  
  return(
    <div>
      <div className="createBetBtn">
        <button className="themeBtn" onClick={()=>setModalShow(true)}>Add Tournament</button>
      </div>
      <div className="table-responsive mt-4 homeTable">

      <table className="table custom-table">
        <thead>
          <tr>
            <td scope="col">Joined</td>
            <td scope="col">Users Joined</td>
            <td scope="col">Round</td>
            <td scope="col">Action</td>
            <td scope="col">Action</td>
          </tr>
        </thead>
        <tbody>
          {tournaments.map(tournament => (
            <TournamentRow tournament={tournament} key={tournament._id} />
          ))}
        </tbody>
      </table>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="betModal">
            <div className="closeIcon" onClick={() => setModalShow(false)}>
              <i className="fas fa-times"></i>
            </div>
            <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
              <h2 className="text-center">Create Tournaments</h2>
                 <div className="row">
                  <div className="col-12 form-group mt-2">
                    <label>Tournament Game</label>
                    <select style={{width: '100%'}} className="mt-2" id="t-game">
                      {['rockpaper', 'dice', 'connect4'].map(g => (
                        <option value={g}>{g}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 form-group mt-2">
                      <label>Name:</label>
                      <input id="t-name" type="text" placeholder="Tournament Name" />
                  </div>
                  <div className="col-12 form-group mt-2">
                    <label>Start:</label>
                    <input id="t-start-date" type="date" />
                  </div>
                  <div className="col-12 form-group mt-2">
                    <label>End:</label>
                    <input id="t-end-date" type="date" />
                  </div>
                </div>
                <div className="text-center m-signin-submit mt-3">
                  <button onClick={createTournament} className="btn">
                    Create
                  </button>
                </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}