import React, { useEffect, useState } from "react";

const SECOND = 1_000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const INTERVAL = SECOND;

export default function TimeRemaining({ time }){
  const [timespan, setTimespan] = useState(new Date(time) - new Date());
  const [days, setDays] = useState('0');
  const [hours, setHours] = useState('0');
  const [minutes, setMinutes] = useState('0');
  const [seconds, setSeconds] = useState('0');

  const getTime = () => {
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimespan((_timespan) => {
        let t = _timespan - INTERVAL;
        setDays(Math.floor(t / (DAY)));
        setHours(Math.floor((t / HOUR) % 24));
        setMinutes(Math.floor((t / MINUTE) % 60));
        setSeconds(Math.floor((t / SECOND) % 60));
        return t;
      });
    }, INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [INTERVAL]);

  return(
    <div>
      {`${days}d:${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`}
    </div>
  );
}