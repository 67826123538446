import React, { useState } from "react";
import { Dropdown, Modal } from 'react-bootstrap'
import PoolEntryRow from "./poolEntryRow";

export default function EnterPoolPvpModal({show, handleClose, pools, selectedPool, setSelectedPool, enterPool, gameDetail, selectedTeam}){
  let [winner, setWinner] = useState('');
  return(
    <Modal id="WalletModal" className="WalletModal predModal" show={show} onHide={() => {handleClose(); setWinner('')}} centered >
      <Modal.Body>
          <div className='betOptionsModal'>
              <h1>Bet Options</h1>
              <div>
                  <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic" className='dropD'>
                          {selectedPool?.points ? selectedPool?.points : '-'} POINTS
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='dropD m-0'>
                          {pools.map(pool => (
                            <PoolEntryRow setSelectedPool={setSelectedPool} key={pool._id} pool={pool}/>
                          ))}
                      </Dropdown.Menu>
                  </Dropdown>
                  {/* <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic" className='dropD'>
                            {winner ? (winner?.name ? winner?.name : 'Draw') : '-'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='dropD m-0'>
                        {selectedTeam === 'draw' ? <></> : <Dropdown.Item onClick={() => setWinner({})}>Draw</Dropdown.Item> }
                        {selectedTeam === gameDetail?.teamA?._id ? <></> : <Dropdown.Item onClick={() => setWinner(gameDetail?.teamA)}>{gameDetail?.teamA?.name}</Dropdown.Item> }
                        {selectedTeam === gameDetail?.teamB?._id ? <></> : <Dropdown.Item onClick={() => setWinner(gameDetail?.teamB)}>{gameDetail?.teamB?.name}</Dropdown.Item> }
                      </Dropdown.Menu>
                  </Dropdown> */}
              </div>
              <button onClick={() => enterPool(winner._id ? winner._id : 'draw')} className="continue">Continue</button>
          </div>
      </Modal.Body>
    </Modal>
  );
}