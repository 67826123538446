import React from 'react'
import virtualLabLogo from "../../imgs/virtualLabLogo.svg"
import stardustLogo from "../../imgs/stardustLogo.svg"
import buy1Logo from "../../imgs/buy1Logo.svg"
export const PartnersSection = () => {
  return (
    <>
        <section className='partnersSec'>
            <h2>Partners</h2>
            <div className="row ai-center">
                <div className="col-sm-4">
                    <div className="imgDiv">
                        <img className='img-fluid' src={virtualLabLogo} alt="" />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="imgDiv">
                        <img className='img-fluid' src={stardustLogo} alt="" />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="imgDiv">
                        <img className='img-fluid' src={buy1Logo} alt="" />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
