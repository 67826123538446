import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalance } from "../../redux/ActionCreators";
import { showToast } from "../../App";
import Slider from '@appigram/react-rangeslider'
import BigNumber from "bignumber.js";

BigNumber.config({ EXPONENTIAL_AT: 10 })

function roundDown(number, decimals=3) {
  decimals = decimals || 0;
  return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
}

export default function RedeemPointsSection({getEthBalance, setDisabled, chain}){
  const authedUser = useSelector(s => s.authedUser);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0.1);
  
  const dispatch = useDispatch();

  const validateLastRedeem = async () => {
    return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/last/claimed`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
    }).then(r => r.json()).then(res => res.success);
  }

  const claimAmount = async () => {
    try {
      if(amount > 0){
        setLoading(true);
        setDisabled(true);
        let canRedeem = false;
        try{
          canRedeem = await validateLastRedeem();
        }catch(e){console.log(e)}
        if (canRedeem){
          console.log(amount);
          let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/redeem-points`, {
            method:'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': `application/json`    
            },
            body : JSON.stringify({
              points: parseInt(amount),
              nonce: Math.floor(Math.random() * 1000000),
              to: authedUser.authedUser.address,
              chain
            })
          }).then(r => r.json())
          if (res.success) {
            console.log(res);
            dispatch(getUserBalance(authedUser.authedUser));
            getEthBalance();
            showToast("Success Claimed")
            setLoading(false)
            setAmount(0);
          } else {
            setLoading(false);
            setDisabled(false);
            showToast(res?.msg);
          }
        }
        else{
          setLoading(false);
          setDisabled(false);
          showToast("Please wait 10 minutes for your next claim");
        }
      } 
      else{
        showToast("Please enter points, you want to claim")
      }
    }
    catch(e) {
      console.log(e);
      showToast("Something went wrong")
      setDisabled(false);
      setLoading(false);
    }
  }

  
  const handleSetAmount = (e) => {
    if(e <= authedUser?.authedUser?.points && e >= 0){
        setAmount(parseFloat(e.toFixed(6)))
    }
  }

  return(
    <>
      <div className="heading my-2">Redeem <span>Honey Points</span></div>
      <div className="sliderMain">
        <div>
          <Slider
          min={0.1}
          max={authedUser?.authedUser?.points}
          step={0.1}
          value={amount}
          onChange={(e)=>handleSetAmount(e)}
          />
        </div>
        <div className="r1">
          <div className="col1">
            <div onClick={() => handleSetAmount(roundDown(authedUser?.authedUser?.points*0.25))} className={`fil ${amount === roundDown(authedUser?.authedUser?.points*0.25)}`}>25%</div>
            <div onClick={() => handleSetAmount(roundDown(authedUser?.authedUser?.points*0.5))} className={`fil ${amount === roundDown(authedUser?.authedUser?.points*0.5)}`}>50%</div>
            <div onClick={() => handleSetAmount(roundDown(authedUser?.authedUser?.points*0.75))} className={`fil ${amount === roundDown(authedUser?.authedUser?.points*0.75)}`}>75%</div>
            <div onClick={() => handleSetAmount(authedUser?.authedUser?.points)} className={`fil ${amount === authedUser?.authedUser?.points}`}>Max</div>
          </div>
        </div>
        <div className="r1 mb-3">
         <div className="col2">
            <div className="pointsVal"><span>{amount}</span> Honey Points / <span>{(new BigNumber(process.env.REACT_APP_POINT_VAL)).dividedBy(new BigNumber(10**18)).multipliedBy(amount).toString()}</span> ETH</div>
          </div>
        </div>
        <div className="r2">
          <div className="balance">Balance: <span>{authedUser.authedUser.points} </span>Honey Points</div>
          <div className="themeBtn" style={{cursor: 'pointer'}} onClick={loading ? () => {} : claimAmount}>{loading ? 'Redeeming...' : 'Redeem'}</div>
        </div>
      </div>
    </>
    // <div>
    //   <br/>
    //   <h4>Redeem Honey Points</h4>
    //   <div>
    //     <input type="number" onChange={(e) => {console.log(e.target.value);setAmount(e.target.value)}} placeholder="Points to redeem" />
    //     <div>Honey Points: {authedUser.authedUser.points}</div>
    //     <button className="theme-btn" onClick={claimAmount}>Redeem Points</button>
    //     {loading ? <div>Redeeming.....</div> : <></>}
    //   </div>
    // </div>  
  );
}