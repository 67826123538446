import React, { useContext, useEffect, useState } from 'react'
import realMadrid from '../../imgs/realMadrid.png'
import fcb from '../../imgs/fcb.png'
import { animate } from '../../js/animate'
import './football.css'
import { useNavigate } from 'react-router-dom'
import { CollapsedContext } from '../../context'

export const FootballPage = () => {
    const navigate = useNavigate();
    const {collapsed} = useContext(CollapsedContext);
    const [games, setGames] = useState([]);
    useEffect(() => {
        animate()
        // window.scrollTo(0, 0,'instant')
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
          });
        getGames();
    }, [])

    const getGames = async () => {
        let {games} = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-live-games`).then(r => r.json());
        setGames(games);
    }
    
  return (
    <main className='px-3 footballPage vipPageCenter' style={{width: collapsed ? `calc(100vw - 85px)` : `calc(100vw - 350px)`}}>
        <section className="bannerSec col-12">
            {/* <h1 className='animatable slideInUp'>Football</h1> */}
        </section>
        <section className='sec2'>
            <h1 className='animatable slideInUp'>Football Predictions</h1>
            <div className="tagline animatable slideInUp animationDelay100">Select your favorite match and decide whether to play against the entire beehive or to challenge a single bee.</div>
        </section>
        <section className='footballSec'>
            <div className="head">Live Games</div>
            <div className="row">
                {games.map(game => (
                <div key={game._id} className="col-xxl-4 col-md-6  mb-3">
                    <PoolCard gameId={game._id} />
                </div>
                ))}
            </div>
            <div className="showMore">View More</div>
        </section>       


        <section className='footballSec'>
            <div className="head">Daily Highlights</div>
            <div className="row">
                <div className="col-xxl-4 col-md-6  mb-3">
                  <PoolCard />
                </div>
                <div className="col-xxl-4 col-md-6  mb-3">
                    <PoolCard />
                </div>
                <div className="col-xxl-4 col-md-6  mb-3">
                    <PoolCard />
                </div>
            </div>
            <div className="showMore">View More</div>
        </section>       

        <section className='footballSec'>
            <div className="head">This Week's Hot Matches</div>
            <div className="row">
                <div className="col-xl-4">
                  <PoolCard />
                </div>
                <div className="col-xl-4">
                  <PoolCard />
                </div>
                <div className="col-xl-4">
                  <PoolCard />
                </div>
            </div>
            <div className="showMore">View More</div>
        </section>       
       
		{/* <Footer /> */}
    </main>
  )
}

const PoolCard = ({gameId}) => {
    const navigate = useNavigate()

    return(
        <div className="footballCard">
            <div className="footballCardMain">
                <div className="fooCard">
                    <div className="imgDiv">
                        <img src={realMadrid} alt="" />
                    </div>
                    <div className="title">REAL MADRID</div>
                    <button onClick={() => navigate('/prediction/football/'+gameId+"?type=rumble")} className="yellowBtn">RUMBLE</button>
                </div>
                <div className="vs">vs</div>
                <div className="fooCard">
                    <div className="imgDiv">
                        <img src={fcb} alt="" />
                    </div>
                    <div className="title">BARCELONA</div>
                    <button onClick={() => navigate('/prediction/football/'+gameId+"?type=pvp")} className="grayBtn">PVP</button>
                </div>
            </div>
            {/* <button className="yellowBtn">Enter Game</button> */}
        </div>
    )
}

