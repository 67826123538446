import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import {loginAdmin} from "../../redux/ActionCreators";

// import { Loader } from "./Loader";
import axios from "axios";

const Signin = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authedUser.authedUser);

  useEffect(() => {
    if (user.token) {
      navigate("/create-bet");
    }
    console.log(user);
  }, [])

  const onSubmit = async () => {
    try {
      setLoader(true);
      const validatorResponse = await validation();

      if (validatorResponse) {
        throw new Error(validatorResponse);
      }

      const payload = {
        email: data.email,
        password: data.password,
      };
      const response = await api.adminLogin(payload);
      console.log(response);
      setLoader(false);
      if (response?.msg == "Please verify your mail first") {
        return alert(response.msg);
      }
      if (response?.error) {
        throw new Error(response?.error);
      }

      alert(response.msg);
      dispatch(loginAdmin(response));
      axios.defaults.headers.common["Authorization"] = response.token;
      console.log("token:" + response.token);
      console.log("res:" + response);
      if (response.token) {
        navigate("/create-bet")
      }
      else {
        navigate("/")
      }
    } catch (e) {
      setLoader(false);
      console.log('====================================');
      console.log(e);
      console.log('====================================');
      alert("Invalid Credentials");
    }
  };

  const validation = async () => {
    if (!data.email) {
      return "email is required";
    }
    if (!data.password) {
      return "Password is required";
    }
    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <section className="loginPage">
        {/* <Header2 /> */}

        <div className="container-fluid px-0">
          <div className="row mx-auto m-signin jc-center">
         
            {/* COLUMN #2 */}
            <div className="col-lg-6 order-lg-2 order-1 px-0">
              <div className="m-signin-sec2 pt-5">

                <form className="loginCard" onSubmit={(e) => e.preventDefault() }>
                <h5 className="text-center">Sign In  </h5>

                  <div className="form-group">
                    <input
                      onChange={onInputChange}
                      name="email"
                      className="text"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="text"
                      onChange={onInputChange}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="text-center m-signin-submit">
                    <button onClick={onSubmit} className="btn ">
                      Sign In
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </section>

      {/* <Loader loader={loader} /> */}
    </>
  );
};

export default Signin;
