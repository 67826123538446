import React, { useEffect, useRef, useState } from 'react'
import profile1 from '../../imgs/profile1.png';
import { useDispatch, useSelector } from 'react-redux';
import { setChatMessageFocus, setServerMessages } from '../../redux/ActionCreators';
import { DateTime } from "luxon";
import SidebarPfp from '../../imgs/sidebar-pfp.png';

async function getInitialChatMessages() {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/chat/get-initial-chat-messages`
    ).then(r => r.json());
    return res;
}

export const Chat = ({chatToggle}) => {
    const authedUser = useSelector((s) => s.authedUser);
    const authUserRef = useRef(null);
    const svMsgsRef = useRef(null);
    const sendBtnRef = useRef(null);
    const { serverMessages, liveMessages, chatMessageFocus } = useSelector(
      (s) => s.chat
    );
    const dispatch = useDispatch();
  
    console.log(authedUser);
    // useRealtimeChat(authedUser.authedUser);
    useEffect(() => {
      if(Object.keys(authedUser?.authedUser).length > 0){
        getInitialChatMessages().then((messages) => {
          dispatch(setServerMessages(messages));
        });
      }else{
        getInitialChatMessages().then((messages) => {
          dispatch(setServerMessages([]));
        });
      }
      authUserRef.current = authedUser;
    }, [authedUser]);
  
    useEffect(() => {
        let chatElem = document.getElementById('chatBar')
        if(chatToggle){
            chatElem.style.right = '0px'
        }else {
            chatElem.style.right = '-370px'
        }
     console.log(chatElem);
    }, [chatToggle])
    

    useEffect(() => {
        const chatMessage = document.getElementById(chatMessageFocus);
        if (chatMessage) {
          chatMessage.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
          dispatch(setChatMessageFocus(""));
        }
    }, [chatMessageFocus]);
    
    const scrollToBottom = () => {
        const container = document.querySelectorAll("#chat > div.chatCard");
        if (container.length) {
          console.log(container);
          container[container.length - 1].scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
    };
    
    useEffect(() => {
        const message = document.querySelector("#message");
        message.addEventListener("keyup", function (event) {
        if (event.key === 'Enter') {
            // sendMessage();
            console.log(sendBtnRef)
            sendBtnRef.current.click();
          }
        });
        return () => {
            message.removeEventListener("keyup", function (event) {
                if (event.key === 'Enter') {
                    // sendMessage();
            console.log(sendBtnRef)

                    sendBtnRef.current.click();
                }
            });
        }
    }, []);
    
    async function sendMessage(e) {
        if (e){ e.preventDefault();}
        // get input field value
        console.log(document.querySelector("#message").value);
        const message = document.querySelector("#message").value;
        console.log(document.querySelector("#message").value, message, authUserRef, authUserRef.current?.authedUser?._id);
        if (message && authedUser?.authedUser?._id) {
            // const res = await fetch(
            // `${process.env.REACT_APP_SERVER_BASE_URL}/chat/add-chat-message`,
            // {
            //     content: message,
            //     sender: authedUser?.authedUser?._id,
            // }
            // );
            console.log('hereeee111222');
            const res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/chat/add-chat-message`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({content: message})
            });
            // getInitialChatMessages().then((messages) => {
            //     dispatch(setServerMessages(messages));
            // });
      
            if (res.ok) {
            document.querySelector("#message").value = "";
            }
            let m = await res.json();
            dispatch(setServerMessages([...serverMessages, m]));
        }
    }
    useEffect(() => {
        scrollToBottom();
        svMsgsRef.current = serverMessages;
    }, [liveMessages, serverMessages]);
    

  return (
    <div className='chatMain' id='chatBar'>
        <div className="heading">
            <img src="" alt="" />
            Chats
        </div>
        <div id='chat' className="chatCardMain">
            <div className="downGradient"></div>
            {serverMessages.map((message) => {
              return (
                // <MessageChat
                //   name={message.sender?.username}
                //   profilePic={message.sender?.profilepic}
                //   message={message.content}
                //   time={message.createdAt}
                //   setId={message._id}
                // ></MessageChat>
                <div key={message._id} className="chatCard">
                    <div className="profile">
                        <img src={message.sender?.profilepic ? `${process.env.REACT_APP_SERVER_BASE_URL}/images/${message?.sender?.profilepic}` : SidebarPfp} alt="" />
                    </div>
                    <div className="msgM">
                        <div className="name">{message.sender?.username}</div>
                        <div className="msg">{message.content}</div>
                    </div>
                    <div className="time">{DateTime.fromISO(message?.createdAt).toRelative()}</div>
                </div>

              );
            })}
            <hr />
            {liveMessages.map((message) => {
              return (
                <div key={message._id} className="chatCard">
                    <div className="profile">
                        <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${message.sendMessage?.profilepic}`} alt="" />
                    </div>
                    <div className="msgM">
                        <div className="name">{message.sender?.username}</div>
                        <div className="msg">{message.content}</div>
                    </div>
                    <div className="time">{DateTime.fromISO(message?.createdAt).toRelative()}</div>
                </div>
              );
            })}

            {/* <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div>
            <div className="chatCard">
                <div className="profile">
                    <img src={profile1} alt="" />
                </div>
                <div className="msgM">
                    <div className="name">Mitchel jhonson</div>
                    <div className="msg">Hi, Jhon could you please send mee...</div>
                </div>
                <div className="time">9:15</div>
            </div> */}
            <div className="searchMain" style={{
                position: 'absolute',
                bottom: '0',
                width: '90%',
                display: 'flex'
            }}>
                <input type="text" placeholder='Send Message' id='message' />
                <button className='theme-btn' ref={sendBtnRef} onClick={sendMessage}>Send</button>
            </div>

        </div>
    </div>
  )
}
