import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PFPAndCoverSection from "./pfpandcoversection";
import TabsSection from "./tabsSection";
import StatsSection from "./statsSection";
import RefferalsSection from "./referralsSection";
import FriendsSection from './friendsSection';
import SettingsSection from './settingsSection';
import { useLocation } from "react-router-dom";

export default function User(){
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(['stats', 'referrals', 'friends', 'settings'].includes(state?.tab) ? state?.tab : 'stats'); /// 'stats', 'settings', 'friends', 'settings'

  useEffect(() => {
    if (['stats', 'referrals', 'friends', 'settings'].includes(state?.tab)){
      setActiveTab(state?.tab);
    }
  }, [state?.tab]);

  return(
    <Container fluid>
      <PFPAndCoverSection />
      <TabsSection activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'stats' ? (
      <StatsSection />
      ) : (<></>)}
      {activeTab === 'referrals' ? (
      <RefferalsSection />
      ) : (<></>)}
      {activeTab === 'friends' ? (
      <FriendsSection/>
      ) : (<></>)}
      {activeTab === 'settings' ? (
      <SettingsSection/>
      ) : (<></>)}
    </Container>
  );
}