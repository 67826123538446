import * as ActionTypes from '../ActionTypes';

export const AuthedAdmin = (state = {
    isLoading: false,
    errMess: null,
    authedAdmin: {}
}, action) => {
        switch(action.type) {
            case ActionTypes.LOGGED_IN_ADMIN:
                localStorage.setItem("admin", action.payload.token);
                return {...state, isLoading: false, errMess: null, authedAdmin: action.payload}
            case ActionTypes.LOGGED_OUT_ADMIN:
              localStorage.removeItem("admin");
              return {...state, isLoading: false, errMess: null, authedAdmin: {}}
            default:
                return state;
        }
};