import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "../../App";

export default function InternalWalletSection({ sdBalance, getEthBalance }){
  const authedUser = useSelector(s => s.authedUser);
  const [maxWithdrawAmount, setMaxAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [canWithdraw, setCanWithdraw] = useState(0);
  const [maxAmt, setMaxAmt] = useState(0);

  return(
    <>
      <div className="heading my-2">Redeem Honey Points</div>
      <div className="sliderMain">
        <div className="inputRow">
          <div className="col1">
            <div className="inputM mb-2">
              <input type="text" name="" id="" value={maxWithdrawAmount} onChange={e => setMaxAmount(e.target.value)}  placeholder="Amount" />
              <div className="balance">Balance: <span>{sdBalance.substring(0,7)}</span> ETH </div>
            </div>
            <div className="col2">
              <div className="fil" onClick={() => setMaxAmount(0.25*maxAmt)}>25%</div>
              <div className="fil" onClick={() => setMaxAmount(0.5*maxAmt)}>50%</div>
              <div className="fil" onClick={() => setMaxAmount(0.75*maxAmt)}>75%</div>
              <div className="fil" onClick={() => setMaxAmount(maxAmt)}>Max</div>
            </div>
          
           
            <div className="sbtn">
              <button className="themeBtn">{loading ? 'Withdrawing...' : 'Submit'}</button>
            </div>
          </div>
       
        </div>
      </div>
    </>
  );
}