import React from "react";
import { Col, Row } from "react-bootstrap";
import coverImg from '../../imgs/cover.png';
import pfpImg from '../../imgs/pfp.png';
import editIcon from '../../imgs/edit-icon.svg'
import editContainer from '../../imgs/edit-container.svg'
import './index.css';
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../App";
import { updateUser } from "../../redux/ActionCreators";


export default function PFPAndCoverSection(){
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();
  const fileHandler = (e) => {
    try {
      if (e.target.files.length > 0){
        console.log(e.target.files);
        console.log(e.target.files[0].size );
        console.log();
        if(e.target.files[0].size > 5000000){
            showToast("Image must be less than 5 mb")
            return
        }

        let formdata = new FormData();
        formdata.append("image", e.target.files[0], e.target.files[0].name);
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/set/profilepic`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: formdata
        }).then(r => r.json())
        .then(res => {
          if (res.success){
            dispatch(updateUser({...authedUser?.authedUser,profilepic:res.user.profilepic}  ));
            showToast("Profile Updated")
          }
        })
      }
    } catch (error) {
      console.log(error);
      showToast("Something went wrong")
    }
  }
  const coverFileHandler = (e) => {
    try {
      if (e.target.files.length > 0){
        console.log(e.target.files);
        console.log(e.target.files[0].size );
        console.log();
        if(e.target.files[0].size > 5000000){
            showToast("Image must be less than 5 mb")
            return
        }

        let formdata = new FormData();
        formdata.append("image", e.target.files[0], e.target.files[0].name);
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/set/coverpic`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: formdata
        }).then(r => r.json())
        .then(res => {
          if (res.success){
            dispatch(updateUser({...authedUser?.authedUser,coverpic:res.user.coverpic}  ));
            showToast("Profile Updated")
          }
        })
      }
    } catch (error) {
      console.log(error);
      showToast("Something went wrong")
    }
  }

  return(
    <Row>
      <Col xs='12' className="cover-and-pfp-container px-0">
          <img className="profile-cover-img" src={authedUser?.authedUser?.coverpic ? `${process.env.REACT_APP_SERVER_BASE_URL}/images/${authedUser?.authedUser?.coverpic}` : coverImg} />
          <div className="cover-edit-container-main">
            <div className="cover-edit-container" onClick={() => document.getElementById('set-cover-direct').click()}>
              <img className="cover-edit-icon" src={editIcon}  />
              <img className="" src={editContainer} />
              <input onChange={coverFileHandler} type="file" accept="image/jpg, image/jpeg" style={{display: 'none'}} id="set-cover-direct" />

            </div>
          </div>
          <div className="profile-img-container">
            <img src={authedUser?.authedUser?.profilepic ? `${process.env.REACT_APP_SERVER_BASE_URL}/images/${authedUser?.authedUser?.profilepic}` : pfpImg} className="profile-profile-img" />
            <div className="profile-username">{authedUser.authedUser?.username} <img onClick={() => document.getElementById('set-pfp-direct').click()} src={editIcon} style={{cursor: 'pointer'}} /> 
            <input onChange={fileHandler} type="file" accept="image/jpg, image/jpeg" style={{display: 'none'}} id="set-pfp-direct" />
            {/* <span>VIP</span> */}
            </div>
            {authedUser?.authedUser?.sdAddress ? (
            <div className="profile-address">{authedUser?.authedUser?.sdAddress.substring(0,5)}....{authedUser?.authedUser?.sdAddress.substring(authedUser?.authedUser?.sdAddress.length-6,authedUser?.authedUser?.sdAddress.length)}</div>
            ) : (<></>)}
          </div>
      </Col>
      <Col xs='12' style={{paddingTop: '140px'}}/>
    </Row>
  );
}