import React, { useEffect, useState } from 'react'
import darkBee from '../../imgs/darkBee.png'
import goldenBee from '../../imgs/goldenBee.png'
import grayBee from '../../imgs/grayBee.png'
import beeCard from '../../imgs/beeCard.png'
import { animate } from '../../js/animate'
import Web3 from "web3";
import web3 from "../../web3"
import BigNumber from "bignumber.js";
import StakingABi from '../../abis/beeStake.json'
import erc20ABi from '../../abis/erc20ABI.json'
import { useSelector } from 'react-redux'
// import { Loader } from 'Component/common/loader'
import { Container, Modal } from 'react-bootstrap'
import { EthereumProvider,  } from '@walletconnect/ethereum-provider'
import walletConnect from "../../imgs/walletConnect.png";
import metamask from "../../imgs/metamask.png";
import './vip.css';
import { Loader } from '../../helpers/loader'

export const VipPage = () => {
    let authedUser = useSelector(state => state.authedUser);

    const [stakedAmount, setStakedAmount] = useState(0);
    const [stakedPackage, setStakedPackage] = useState(0);
    const [rewardAmount, setRewardAmount] = useState(0);
    const [staking, setStaking] = useState("");
    const [nftToken, setNftToken] = useState(0);
    const [totalStaked, setTotalStaked] = useState(0);
    const [address, setAddress] = useState("");
    const [isConnected,setIsConnected]=useState(false); 

    const [loading,setLoading] = useState(false);
    const [loaderMsg,setLoaderMsg] = useState(false);

    const [showWalletModal, setShowWalletModal] = useState(false);
    const handleCloseWalletModal = () => setShowWalletModal(false);
  
    const handleShowWalletModal = () => {
      // if(isTabletOrMobile){
      // 	switchToDesktop()
      // 	return
      // }
      setShowWalletModal(true)
    };

    const getContract = (web3, abi, address) => new web3.eth.Contract(abi, address);
    const toHex = (amount) => "0x" + amount.toString(16);

  const web33 = new Web3(process.env.REACT_APP_NODE_URL_RPC);

    let STAKE = getContract(
        web33,
        StakingABi,
        process.env.REACT_APP_STAKE_ADDRESS
      );
    let POINTS = getContract(
        web3.web3,
      erc20ABi,
      process.env.REACT_APP_ERC20_ADDRESS
    );

    const getStats = async () => {
        console.log(STAKE);
        let totalToks = await STAKE.methods.totalStaked().call();
        console.log("totalToks");
        console.log(totalToks);
        console.log(new BigNumber(totalToks) / 10 ** 9);
        setTotalStaked(new BigNumber(totalToks) / 10 ** 9);
    };

    const isApproved = async (tok, owner, approvedAddress, totalAmount) => {
        try {
          let r = await tok.methods.allowance(owner, approvedAddress).call();
          console.log("approved",r);
          if (parseInt(r) <= totalAmount) {
            let val = new BigNumber(web3.web3.utils.toWei("10000000000","ether"));
            let finPrice = toHex(val);
            let a = await tok.methods.approve(approvedAddress, finPrice).send({
              from: owner,
            });
            console.log(a);
            return true;
          } else {
            return true;
          }
        } catch (e) {
          console.log("Approve Issue", e);
          return false;
        }
      };

      const stakeToken = async (pkg,stakingAmount) => {
        try {       
            if(!address){
              setLoading(true)
              setLoaderMsg("Please connect to wallet")
              setTimeout(() => {
                  setLoading(false)
                  setLoaderMsg("")
              }, 3000);
              return ;
            }
            console.log("stakingAmount1",stakingAmount);
            console.log("pkg",pkg);
    
            if (address) {
                let val = stakingAmount;
                let k = 1000;
                console.log(val);
                if (val) {
                setLoading(true)
                let isValid = false, errMsg = '';
                if (pkg === 1){
                  if (parseFloat(val) >= 10*k && parseFloat(val) <= 50*k) {
                    isValid = true;
                  }
                  else{
                    errMsg = "For Silver Bee Staking, Minimum: 10,000, Maximum: 50000 BEE Need To Be Locked";
                  }
                }
                else if (pkg === 0){
                  if (parseFloat(val) == 100*k){
                    isValid = true;
                  }
                  else{
                    errMsg = "For Golden bee Staking,  100000 BEE Need To Be Locked";
                  }
                }
            
                if (isValid) {
                    console.log("val");
                    console.log(val);
                    val = new BigNumber(val * 10 ** 9);
                    val = noExponents(val.toString())
                    console.log(parseInt(val));
                    console.log(val.toString());
                    // val = toHex(val);
                    // console.log(val);
    
                  let STAKE1 = getContract(
                    web3.web3,
                    StakingABi,
                    process.env.REACT_APP_STAKE_ADDRESS
                  );
                  console.log("STAKE1");
                  console.log(STAKE1);
                  let TOKEN = getContract(
                    web3.web3,
                    erc20ABi,
                    process.env.REACT_APP_ERC20_ADDRESS
                  );
                  let blnc = await TOKEN.methods.balanceOf(address).call();
                  console.log("balanceOf",parseInt(blnc));
                  if (parseInt(blnc) >= val) {
                    let app = await isApproved(
                      TOKEN,
                      address,
                      process.env.REACT_APP_STAKE_ADDRESS,
                      val
                    );
    
                    if (app) {
                        console.log("call method");
                        console.log("pkg",pkg);
                        console.log("amount",val);
                        console.log(address);
                      await STAKE1.methods.StakeAmount(pkg,val).send({
                        from: address
                      });
                      getStakerInfo(address);
                      getStats();
                        setLoaderMsg("Staked successfully")
                        setTimeout(() => {
                            setLoading(false)
                            setLoaderMsg("")
                        }, 3000);
                    } else {
                         setLoaderMsg("Tokens not approved")
                        setTimeout(() => {
                            setLoading(false)
                            setLoaderMsg("")
                        }, 3000);
                    }
                } else {
                       setLoaderMsg("Insufficient Tokens")
                        setTimeout(() => {
                            setLoading(false)
                            setLoaderMsg("")
                        }, 3000);
                    }
                }
                else{
                  setLoaderMsg(errMsg)
                        setTimeout(() => {
                            setLoading(false)
                            setLoaderMsg("")
                    }, 3000);
                }
              } else {
                setLoading(true)
                setLoaderMsg("Enter amount to stake")
                setTimeout(() => {
                    setLoading(false)
                    setLoaderMsg("")
                }, 3000);
              }
            } else {
              setLoading(true)
              setLoaderMsg("Connect Wallet")
              setTimeout(() => {
                  setLoading(false)
                  setLoaderMsg("")
              }, 3000);
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            setLoaderMsg("")
        }
    
        // await getBalance()
      };

      
  const getStakerInfo = async (address) => {
    setLoading(true)

    let res = await STAKE.methods.stakers(address).call();
    console.log("staked token",res);
    if (new BigNumber(res.stakedAmount) > 0) {
      let rew = await STAKE.methods.showRewards(address).call();
      setRewardAmount( new BigNumber(rew));
    }
    let amnt = new BigNumber(res.stakedAmount) / 10 ** 9;
    let stPackage = parseInt(res.package);
    let tokenId = parseInt(res.tokenId);
    console.log(stPackage);
    console.log(amnt);
    setNftToken(tokenId)
    setStakedPackage(stPackage);
    setStakedAmount(amnt);
    setLoading(false)

  };

  
  const noExponents = (num) => {
    var data = String(num).split(/[eE]/);
    if (data.length == 1) return data[0];
  
    var z = '',
      sign = this < 0 ? '-' : '',
      str = data[0].replace('.', ''),
      mag = Number(data[1]) + 1;
  
    if (mag < 0) {
      z = sign + '0.';
      while (mag++) z += '0';
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
  }

  const unstakeToken = async () => {
    if (address) {
        setLoading(true)

        let STAKE1 = getContract(
            web3.web3,
            StakingABi,
            process.env.REACT_APP_STAKE_ADDRESS
          );
          console.log("STAKE1");
          console.log(STAKE1);
      try {
        let a = await STAKE1.methods.endStake().send({
          from: address,
        });
        getStakerInfo(address?.toString()?.toLowerCase());
        getStats();
        setLoaderMsg("Unstaked successfull")
        setTimeout(() => {
            setLoading(false)
            setLoaderMsg("")
        }, 3000);
        setLoading(false)
        
    } catch (e) {
        setLoaderMsg("Failed")
        setTimeout(() => {
            setLoading(false)
            setLoaderMsg("")
        }, 3000);
        console.log(e);
        setLoading(false)
      }
    } else {
      setLoaderMsg("Connect Wallet")
      setTimeout(() => {
          setLoading(false)
          setLoaderMsg("")
      }, 3000);
    }

  };
    

  const loginMetamask = async()=>{
		try {

			if (!window?.ethereum){
				alert('Install Metamask');
				// setMsgText("Install Metamask")
			  }
			  else if (parseInt(await window.ethereum.request({
				method: 'eth_chainId',
			  })) !== parseInt(process.env.REACT_APP_CHAIN_ID)
			  ){
				web3.web3 = new Web3(window.ethereum);
				
				console.log(parseInt(await window.ethereum.request({
					method: 'eth_chainId',
				  })));
				console.log(process.env.REACT_APP_CHAIN_ID);
				alert(`Connect to ${process.env.REACT_APP_NETWORK_NAME}`);
			  }
			  else{
				web3.web3 = new Web3(window.ethereum);
				let accs = await window.ethereum.request({
				  method: 'eth_requestAccounts'
				});
				if (accs.length > 0){
					let ad = accs[0].toLowerCase();
					let balance1 = await web3.web3.eth.getBalance(ad)
					let balance = web3.web3.utils.fromWei(parseInt(balance1),'ether')

					console.log(accs);
					
					  let signature, signed = true;
					  try{
						setLoading(true)
						setLoaderMsg("Sign to Verify your Wallet")
            let nonce = Math.floor(Math.random() * 1000000);
						signature = await web3.web3.eth.personal.sign(
						  `I am signing my one-time nonce: ${nonce}`,
						  accs[0],
						  ''
						);  
					  }
					  catch(e){
						console.log(e);
						signed = false;
						alert("Need to sign signature to continue.")
						setLoaderMsg("")
						setLoading(false)
					  }
					  if (signed){
						console.log(signature);
						console.log(ad);
						
						  let chainId = parseInt(await window.ethereum.request({
							method: 'eth_chainId',
						  }))
    					let balance1 = await web3.web3.eth.getBalance(ad)
						  balance = parseFloat(balance);
              setAddress(ad)
						  setIsConnected(true)
						  handleCloseWalletModal()
						  setLoaderMsg("")
						  setLoading(false)
					}
					else{
						alert("Invalid Signature")
						setLoaderMsg("")
						setLoading(false)
						}
					}
				  }
		} catch (error) {
			console.log(error);
			setLoaderMsg("")
			setLoading(false)
		}
	}

  
	const handleWalletConnect=async ()=>{
		try {
			const providerOptions = await EthereumProvider.init({
			  projectId: process.env.REACT_APP_WC_PROJECT_ID, // REQUIRED your projectId
			  chains: [parseInt(process.env.REACT_APP_CHAIN_ID)], // REQUIRED chain ids
			  showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
				
			});
			handleCloseWalletModal()

			// localStorage.clear()
			clearIndexedDBData()
			await providerOptions.disconnect()
			var connected = await providerOptions.enable()
			console.log("connected");
			console.log(connected);
	  
			  web3.web3 = new Web3(providerOptions);
			// web3.web3 = web3wwwwww;
			console.log("chainID");
			console.log( providerOptions.chainId.toString(10));
			let chainChanged = true;

			if (parseInt(providerOptions.chainId) !== parseInt(process.env.REACT_APP_CHAIN_ID)){
			  try {	
				  console.log(providerOptions.chainId);
				  // alert('Connect to ETH or BSC');
				  //   alert("Connect to Sepolia testnet")
					await providerOptions.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
					});
					web3.web3 = new Web3(providerOptions);
			  } catch (e) {
					chainChanged = false;
					console.log(e);
			  }
			}
			if (chainChanged){
				let accs = await web3.web3.eth.getAccounts();
				console.log('====================================');
				console.log(accs);
				console.log('====================================');
				if (accs.length > 0){
				  console.log("acc");
				let ad = accs[0].toLowerCase();
				console.log(ad);
				let balance1 = await web3.web3.eth.getBalance(ad)
				let balance=await web3.web3.utils.fromWei(parseInt(balance1),'ether')
				console.log(balance);

				let noToken = true;
				console.log(accs);
					setLoading(true)
					setLoaderMsg("Sign to Verify your Wallet")
				  let signature, signed = true;
				  try{
          let nonce = Math.floor(Math.random() * 1000000);
					const message = `I am signing my one-time nonce: ${nonce}`;
					signature = await providerOptions.request({
						method: 'personal_sign',
						params: [message, accs[0]],
					});				
					// signature = await web3.web3.eth.personal.sign(
					//   `I am signing my one-time nonce: ${r.user.nonce}`,
					//   accs[0],
					//   ''
					// );  
				  }
				  catch(e){
					console.log(e);
					signed = false;
					setLoading(false)
					setLoaderMsg("")
					alert("Need to sign signature to continue.")
				  }
				  if (signed){
					console.log(signature);
					console.log(ad);
			
					  let chainId = providerOptions.chainId
					  balance = parseFloat(balance);

            setAddress(ad)
					  setIsConnected(true)
					  handleCloseWalletModal()
					  setLoading(false)
					  setLoaderMsg("")
				  } 
			  }
		  }
		  else{
			alert(`Please connect ${process.env.REACT_APP_NETWORK_NAME}`)

		  }
		} catch (error) {
			console.log(error);
			setLoading(false)
			setLoaderMsg("")
			alert("Something went wrong")
		}
	}

  const clearIndexedDBData = async () => {
		try {
		  // Open the IndexedDB database
		  const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
		  const request = indexedDB.open('WALLET_CONNECT_V2_INDEXED_DB');
	
		  request.onsuccess = (event) => {
			const db = event.target.result;
			// Open a transaction to access the object store where WalletConnect data is stored
			const transaction = db.transaction(['keyvaluestorage'], 'readwrite');
			const objectStore = transaction.objectStore('keyvaluestorage');
	
			// Clear the data related to WalletConnect
			const clearRequest = objectStore.clear();
	
			clearRequest.onsuccess = () => {
			  console.log('WalletConnect data cleared successfully from IndexedDB');
			};
	
			clearRequest.onerror = (error) => {
			  console.error('Error clearing WalletConnect data from IndexedDB:', error);
			};
		  };
		  request.onblocked = function () {
			console.log("Couldn't delete database due to the operation being blocked");
		};
	
		  request.onerror = (error) => {
			console.error('Error opening IndexedDB:', error);
		  };
		} catch (error) {
		  console.error('Error clearing WalletConnect data from IndexedDB:', error);
		}
	  };

    useEffect(() => {
        getStats();
        if(authedUser?.authedUser) {
        //   getBalance();
        }
      }, []);

      useEffect(() => {
        if (address) {
          getStakerInfo(address?.toString()?.toLowerCase());
        } else {
          setStakedAmount(0);
        }
      }, [authedUser?.authedUser]);

    useEffect(() => {
        animate()
        // window.scrollTo(0, 0,'instant')
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
          });
    }, [])
    
  return (
    <Container fluid >
      <main className='vipPageCenter '>
          <section className="bannerSec">
              <h1 className='animatable slideInUp'>Become a VIP Member</h1>
              <div className="tagline animatable slideInUp">Secure your BeeCasino shares with our exclusive Bee Passes.</div>
              {/* <div className="joinBtn">
                  <button className="themeBtn">Join Now</button>
              </div> */}
          </section>
          <section className='sec2'>
              {/* <h1 className='animatable slideInUp'>Elevate your experience with our VIP program rewards</h1> */}
              <div className="tagline animatable slideInUp animationDelay100">Mint your <span>Free Pass</span> to gain access to exclusive VIP benefits.</div>
          </section>
          <section className='tableSec'>
              <div className="table-responsive">
              <table className="table table-striped mb-0">
                  <thead>
                      <tr>
                      <th scope="col"><div className="themeYellow"> BeeCasino BeePass</div></th>
                      <th scope="col">
                          <div className="themeYellow">Golden Bee</div>
                          {/* <img src={beeCard} alt="" className="card1" /> */}
                      </th>
                      <th scope="col">
                          {/* <img src={beeCard} alt="" className="card2" /> */}
                          <div className="themeYellow">Silver Bee</div>
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                      <td scope="row"><div className="inner">TOKENS REQUIRED</div></td>
                      <td><div className="inner">100,000</div></td>
                      <td><div className="inner"> MIN: 10,000, MAX: 50,000</div></td>
                      </tr>
                      <tr>
                      <td scope="row"><div className="inner"> TOTAL SHARES</div></td>
                      <td><div className="inner font-Gotham-medium">3%</div></td>
                      <td><div className="inner font-Gotham-medium">1.5%</div></td>
                      </tr>
                      <tr>
                      <td scope="row"><div className="inner"> SPOTS AVAILABLE</div></td>
                      <td><div className="inner font-Gotham-medium">500</div></td>
                      <td><div className="inner font-Gotham-medium">1000</div></td>
                      </tr>
                      <tr>
                      <td scope="row"><div className="inner"> VIP TOURNAMENTS</div></td>
                      <td><div className="inner font-Gotham-medium">V</div></td>
                      <td><div className="inner font-Gotham-medium">V</div> </td>
                      </tr>
                  </tbody>
                  </table>
              </div>
          </section>
          <div className='my-4 text-center'>
            {address ?
            <button type="button" className="themeBtn" >
              {address.slice(0,5)}...{address.slice(38,address.length)}
            </button>
            :
            <button type="button" className="themeBtn" onClick={handleShowWalletModal}>Connect Wallet</button>
            }
          </div>

          <section className='sec3VipAccess pb-0'>
              <GoldenBee nftToken={nftToken} stakeToken={stakeToken} stakedPackage={stakedPackage} stakedAmount={stakedAmount} unstakeToken={unstakeToken}  />
          </section>
          <section className='sec3VipAccess'>
              <SilverBee nftToken={nftToken} stakeToken={stakeToken} setStaking={setStaking} staking={staking} stakedPackage={stakedPackage} stakedAmount={stakedAmount} unstakeToken={unstakeToken} />
          </section>

          <Loader loader={loading} msg={loaderMsg} />

          <Modal id="WalletModal" className="WalletModal" show={showWalletModal} onHide={handleCloseWalletModal} centered >
          <Modal.Body>
            <div >
            <h1>Connect your web3 wallet</h1>
            <div className="walletRow">
              <div className="metamask">
              <div className="walletImg">
                <img className="img-fluid" src={metamask} alt="metamask" />
              </div>
              <div className="btnDiv">
                <button className="" onClick={loginMetamask}><span className="db-sm">Metamask</span> <span className="db-lg">Connect Metamask</span></button>
              </div>
              </div>
              <div className="walletConnect">
              <div className="walletImg">
                <img className="img-fluid" src={walletConnect} alt="metamask" />
              </div>
              <div className="btnDiv" >
                <button className="" onClick={handleWalletConnect} >WalletConnect</button>
              </div>
              </div>
            </div>
            <div className="closeIcon" onClick={handleCloseWalletModal}>
            <i class="fas fa-times"></i>
            </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    </Container>
  )
}

const GoldenBee = ({stakeToken,stakedPackage,stakedAmount,unstakeToken,nftToken}) => {
    return(
        <div className="aContainer">
                <h2 className="heading mb-3 animatable slideInUp">VIP Access</h2>
                <div className="vipRow1">
                  <div className="row ai-center">
                    <div className="col-xl-3">
                      <div className="col1">
                        <div className="goldHead themeYellow animatable slideInUp animationDelay100">Golden Bee</div>
                        <div className="goldHead themeYellow animatable slideInUp animationDelay200">Earn 3%</div>
                        <div className="tagline animatable slideInUp animationDelay300">BeeCasino Platform Revenue</div>
                        <div className="locking">By locking 100,000 $BEE</div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="col2">
                        <div className="stakeAmount">
                          <div className="val">100,000</div>
                          {stakedPackage == 1 && stakedAmount > 0 ?
                          <div className="amount">Staked {Math.round(stakedAmount)}</div>
                          :
                          <div className="amount">Stake 100,000</div>
                          }
                        </div>
                        {stakedPackage == 1 && stakedAmount > 0 ?
                          <>
                          <button className="stakeBtn" onClick={()=> unstakeToken()} >UN-STAKE</button>
                          </>
                          :
                          <>
                          <button disabled={stakedAmount > 0 ?true:false} className="stakeBtn" onClick={()=> stakeToken(0,100000)} >STAKE</button>
                       
                          </>
                          }
                      
                      </div>
                    </div>
                    <div className="col-xl-3">
                      <div className="col3">
                        <div className="r1">
                          <div className="goldHead">25M <br /> $BEE</div>
                          <div className="tagline">Total  <br /> Value <br /> Locked</div>
                        </div>
                        <div className="freeBee mt-auto">Stake and get</div>
                        <div className="freeBee">Free Golden Bee</div>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
    )
}
const SilverBee = ({stakeToken,setStaking,staking,stakedPackage,stakedAmount,unstakeToken,nftToken}) => {
    return(
        <div className="aContainer">
                  <div className="vipRow1 silver">
                  <div className="row ai-center">
                    <div className="col-xl-3">
                      <div className="col1">
                        <div className="silverHead themeYellow animatable slideInUp animationDelay100">Silver Bee</div>
                        <div className="silverHead themeYellow animatable slideInUp animationDelay200">Earn 1.5%</div>
                        <div className="tagline animatable slideInUp animationDelay300">BeeCasino Platform Revenue</div>
                        <div className="locking">By locking 100,000 $BEE</div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="col2">
                        <div className="stakeAmount">
                          <div className="val">50,000</div>
                           {stakedPackage == 2 && stakedAmount > 0 ?
                            <div className="amount">Staked {Math.round(stakedAmount)}</div>
                            :
                            <>
                            <div className="amount">Stake 50,000</div>
                            </>
                            }
                        </div>
                             {stakedPackage == 2 && stakedAmount > 0 ?
                            <>
                            <button className="stakeBtn silver" onClick={()=> unstakeToken()}>UN-STAKE</button>
                            </>
                            :
                            <>
                            {/* <button disabled={stakedAmount > 0 ?true:false} className="stakeBtn mb-3" onClick={()=> stakeToken(1,staking)} >STAKE</button> */}
                            <button disabled={stakedAmount > 0 ?true:false} className="stakeBtn silver" onClick={()=> stakeToken(1,50000)} >STAKE</button>
                            </>
                            }
                      
                      </div>
                    </div>
                    <div className="col-xl-3">
                      <div className="col3">
                        <div className="r1">
                          <div className="silverHead">25M <br /> $BEE</div>
                          <div className="tagline">Total  <br /> Value <br /> Locked</div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>

             
            </div>
    )
}
