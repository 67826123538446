import React from "react";
import { Col, Row } from "react-bootstrap";
import profileImg from '../../imgs/pfp.png'
import msgIco from '../../imgs/msgIco.svg'
import addFirendIco from '../../imgs/addFirendIco.svg'
import dp from '../../imgs/dp.png'

export default function FriendsSection(){
  return(
    <>
    <div className="friendsSecM">
      <div className="heading my-3">Friend Requests</div>
      <Row>
        <Col md='4' >
          <ReqCard />
        </Col>
        <Col md='4' >
          <ReqCard />
        </Col>
        <Col md='4' >
          <ReqCard />
        </Col>
      </Row>
      <div className="seeMore">See More</div>

      {/* Your Friends section */}
      <div className="heading mb-3">Your Friends</div>
      <div className="friendSec">
       <FCard />
       <FCard />
       <FCard />
       <FCard />
       <FCard />
       <FCard />
       <FCard />
       <FCard />
       <FCard />
      </div>

      <div className="heading mb-0">Add Friends</div>
      <div className="mayLike mb-4">People you may like</div>
      <Row>
        <Col lg='3' >
          <AddFriendCard />
        </Col>
        <Col lg='3' >
          <AddFriendCard />
        </Col>
        <Col lg='3' >
          <AddFriendCard />
        </Col>
        <Col lg='3' >
          <AddFriendCard />
        </Col>
      </Row>

      <div className="seeMore">See More</div>

    </div>




    </>
  );
}

const ReqCard = () => {
  return(
    <div className="reqCard">
            <div className="imgDiv">
              <img src={profileImg} alt="profile" />
            </div>
            <div className="content">
              <div className="name">Markiplier <span>VIP</span></div>
              <div className="btnM">
                <button className="confirm">Confirm</button>
                <button className="delete">Delete</button>
              </div>
            </div>
          </div>
  )
}

const FCard = () => {
  return(
    <div className="fCard">
      <div className="imgDiv">
        <img src={profileImg} alt="" />
      </div>
      <div className="name">Markiplier</div>
      <button className="cBtn">Challenge</button>
      <div className="iconM">
        <div className="icon"><img src={msgIco} alt="" /></div>
        <div className="icon"><img src={addFirendIco} alt="" /></div>
      </div>
    </div>
  )
} 

const AddFriendCard = () => {
  return(
    <div className="addFriendCard">
      <div className="imgDiv">
        <img src={dp} alt="" />
      </div>
      <div className="content">
        <div className="name">JuegaGerman</div>
        <div className="mutual"><span>14</span> mutual friends</div>
        <div className="btnM">
          <button className="confirm">Confirm</button>
          <button className="delete">Delete</button>
        </div>
      </div>
    </div>
  )
}