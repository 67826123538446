import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import logoIcon from '../../imgs/logo-icon.svg';
import tournamentImg1 from '../../imgs/dashboard-tournament-1.svg'
import tournamentImg2 from '../../imgs/dashboard-tournament-2.svg'
import tournamentImg3 from '../../imgs/dashboard-tournament-3.svg'
import { useNavigate } from "react-router-dom";
import { BsFillDice6Fill } from "react-icons/bs";
import { CgEditFade } from "react-icons/cg";
import { RiScissorsCutFill } from "react-icons/ri";

const tournImgs = {'dice': <BsFillDice6Fill size={'48px'} />, 'rockpaper': <RiScissorsCutFill size={'48px'} />, 'connect4': <CgEditFade size={'48px'} />};


const tImgs = [tournamentImg1, tournamentImg2, tournamentImg3];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default function FirstSection(){
  let navigate = useNavigate();
  const [latestTournaments, setLatestTournaments] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/tournaments/latest`)
    .then(r=>r.json())
    .then(r => r.success ? setLatestTournaments(r.tournaments) : '');
  }, []);
  
	const getRandomLink = () => {

		// if(authedUser?.token){
			const links = [`https://dice.${window.location.host}`,
							`https://rps.${window.location.host}`,
							`https://connect4.${window.location.host}`,
							// '/coinflip'
							];
			let item = links[Math.floor(Math.random()*links.length)];
			if(item == "/coinflip"){
				navigate(item)
			}else {
				window.location.href = item
			}
		// }else{
		// 	alert("Please Connect Wallet First")
		// }
	}

  return(
    <Row className='first-section'>
      <Col xs='12' xl='7' xxl='8'  >
      <Col xs='12' className="play-now-first-section">
        <img src={logoIcon} />
        <div className="animatable slideInUp">
          <div className="header-play-now-text mb-3">JACKPOTS</div>
          <div className="header-play-now-text2 ">Join one of our games and seize the chance to win BIG JACKPOTS!</div>

          {/* <div className="header-play-now-text">$5,000 <br /> Monthly for each game we have.</div> */}
          {/* <div className="unleash-text">Unleash the potential to insane earning</div> */}
        </div>
        <button className="theme-btn" onClick={() => getRandomLink()}>Play Now</button>
      </Col>
      </Col>
      <Col xs='12' xl='5' xxl='4'>
      <Col xs='12' className="tournaments-first-section">
        <div className="upcoming-tournaments-header">
          <div className="upcoming-tournaments">Upcoming Tournaments</div>
          <div className="upcoming-tournaments-tags">
            <span className="theme-btn-green px-3 py-1">December</span>
            <span className="theme-btn-green mx-2 px-3 py-1">8 Activity</span>
          </div>
        </div>
        <div className="animatable slideInUp">

          {latestTournaments.map((t, i) => {
            return(
              <div key={t._id} className="upcoming-tournament-card">
                <span>{tournImgs[t.gameName]}</span>
                <div className="upcoming-tournament-card-text">
                  <div className="upcoming-tournament-name">{t.name}</div>
                  {/* <div className="upcoming-tournament-date">{(new Date(t.start_time)).getDate()} - {(new Date(t.end_time)).getDate()} {months[(new Date(t.end_time)).getMonth()]}</div> */}
                  <div className="upcoming-tournament-date">
                    Coming Soon
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className="upcoming-tournament-card">
            <img src={tournamentImg1} />
            <div className="upcoming-tournament-card-text animatable slideInUp ">
              <div className="upcoming-tournament-name">Global Champion Firenort 2</div>
              <div className="upcoming-tournament-date">12 - 25 Dec</div>
            </div>
          </div>
          <div className="upcoming-tournament-card">
            <img src={tournamentImg2} />
            <div className="upcoming-tournament-card-text animatable slideInUp animationDelay100">
              <div className="upcoming-tournament-name">Asia Tournament Relium</div>
              <div className="upcoming-tournament-date">1 - 12 Dec</div>
            </div>
          </div>
          <div className="upcoming-tournament-card">
            <img src={tournamentImg3} />
            <div className="upcoming-tournament-card-text animatable slideInUp animationDelay200">
              <div className="upcoming-tournament-name">SRML Firehearth 2023</div>
              <div className="upcoming-tournament-date">14 - 25 Dec</div>
            </div>
          </div> */}
        </div>
      </Col>
      </Col>
    </Row>
  );
}