import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { showToast } from "../../App";
import { useDispatch } from "react-redux";
import { getUserBalance, loginUser } from '../../redux/ActionCreators';
import showPassImg from '../../imgs/showPass.svg';
import hidePass from '../../imgs/hidePass.svg';
import { useSearchParams } from "react-router-dom";
import logo from '../../imgs/logo.svg';
import google from '../../imgs/google.png';
import twitter from '../../imgs/twitter.png';
import Login from "./login";

export default function SignupBtn(){
  const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
  const [signupModal, setSignupModal] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

	const signup = async () => {
		let email = document.getElementById('sign-up-email').value;
		let uname = document.getElementById('sign-up-uname').value;
		let pwd = document.getElementById('sign-up-pwd').value;
		var expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		if (expression.test(email)){
			fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/register`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email: email, pwd: pwd, uname: uname, code: searchParams.get('code') ? searchParams.get('code') : '' })
			}).then(r => r.json())
			.then(res => {
				if (res.success){
					res.user.token = res.tok;
					dispatch(loginUser(res.user));
					dispatch(getUserBalance(res.user));
				}
				else{
					showToast(res.msg);
				}
			})			
			setSignupModal(false);
		}
		else{
			showToast('Invalid email address');
		}
	}

  return(
    <>
      <div onClick={() => {setSignupModal(true);setShowLogin(false)}} className="signupBtn" >
        Signup
      </div>
	  <div onClick={() => {setSignupModal(true);setShowLogin(true)}}  className="signBtn">
            Login
      </div>
			<Modal id="WalletModal" className="signModal" show={signupModal} onHide={() => setSignupModal(false)} centered >
				<Modal.Body>
					<div className="logo"><img className="img-fluid" src={logo} alt="" /></div>
					<div className="navigate">
						<button className={showLogin ?"active":""}  onClick={()=>setShowLogin(true)}>Sign in</button>
						<button className={showLogin ?"":"active"}  onClick={()=>setShowLogin(false)}>Sign up</button>
					</div>
					{showLogin ?
					<Login setSignupModal={setSignupModal} setShowLogin={setShowLogin} />
					:
					<div>
						
						<div >
							<div className="inputMain">
								<i className="fa-solid fa-envelope ico" />
								<input type="text" id="sign-up-email" placeholder="Email..." />
							</div>
							<div className="inputMain">
								<i class="fa-solid fa-user ico"></i>
								<input type="text" id="sign-up-uname" placeholder="Username..." />
							</div>
							<div className="inputMain">
								<i class="fa-solid fa-lock ico"></i>
								<div className="passInput w-100">
									<input type={showPass1?"text":"password"} id="sign-up-pwd" placeholder="Password..." />
									{showPass1?
									<img onClick={()=>setShowPass1(false)} src={showPassImg} alt="" />
									:
									<img onClick={()=>setShowPass1(true)} src={hidePass} alt="" />
									}
								</div>
							</div>
							<div className="btnDiv my-3">
								<button className="themeBtn w-100" onClick={signup}>
									Continue
								</button>
							</div>
							<div className="or">
								<span></span>
								<div className="px-2">Or</div>
								<span></span>
							</div>
							<div className="inputMain">
								<img src={google} alt="" className="ico me-3" />
								<div className="text-center w-100">
									Continue with Google
								</div>
							</div>
							<div className="inputMain">
								<img src={twitter} alt="" className="ico me-3" />
								<div className="text-center w-100">
									Continue with X
								</div>
							</div>
							<div className="already">Already have an account? <span onClick={()=>setShowLogin(true)}>Sign In</span></div>
						</div>
						<div className="closeIcon" onClick={() => setSignupModal(false)}>
						<i className="fas fa-times"></i>
						</div>
					</div>
					}

				</Modal.Body>
			</Modal>
    </>
  );
}