import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
// import { Loader } from "./Loader";
import Modal from 'react-bootstrap/Modal';
import web3 from "../../web3"

// import { Loader } from "../../Component/common/loader";
import BettingABI from '../../abis/contractABI.json';
import axios from "axios";
import TeamSection from "./teamsection";
import GameSection from "./gamesection";
import TournamentSection from "./tournamentSection";
import { UserPointsPage } from "./UserPointsPage";
import { AdminNavbar } from "./adminNavbar";
import { showToast } from "../../App";


 
export const  CreateBet = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const authedAdmin = useSelector((state) => state.authedAdmin);
  const [modalShow, setModalShow] = useState(false);
  const [betData, setBetData] = useState([]);
  const [img, setImg] = useState("");
  const [adminPoint, setAdminPoint] = useState(0);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [page, setPage] = useState(1);



  useEffect(() => {
    getAllBet();
    getTeams();
    getGames();
  }, [])

  const getAllBet = async() => {
    const response = await api.getBetAdmin()
    console.log(response);
    setBetData(response.bet)
  }
  
  const getTeams = async() => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/teams`, {
      method: 'GET',
      headers:{
        'Authorization': localStorage.getItem('admin')
      }
    }).then(r => r.ok ? r.json() : {teams: []});
    setTeams(response.teams)
  }

  const getGames = async() => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/games`, {
      method: 'GET',
      headers:{
        'Authorization': localStorage.getItem('admin')
      }
    }).then(r => r.ok ? r.json() : {games: []});
    console.log("--------->",response)
    setGames(response.games)
  }

  const noExponents = (num) => {
    var data = String(num).split(/[eE]/);
    if (data.length == 1) return data[0];

    var z = '',
      sign = this < 0 ? '-' : '',
      str = data[0].replace('.', ''),
      mag = Number(data[1]) + 1;

    if (mag < 0) {
      z = sign + '0.';
      while (mag++) z += '0';
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
  }

  const onSubmit = async () => {
    try {
      const validatorResponse = validation();
      console.log(validatorResponse)
      if (validatorResponse) {
        return  showToast(validatorResponse);
      }
      let sendAmount = parseFloat(data.amount)
      console.log(noExponents(sendAmount));
      console.log(typeof sendAmount);
      console.log(web3.web3.utils.toWei(sendAmount,'ether') );
      setLoader(true);

      const response1 = await api.getBetFromAmount(noExponents(sendAmount))
      console.log(response1);
      if(response1?.bet[0]?.amount){

        let formdata = new FormData();
        formdata.append("cardImg", img, img.name);
        formdata.append("betType", response1?.bet[0]?.betType);
        formdata.append("amount",  noExponents(sendAmount));
        formdata.append("noOfCards", data.noOfCards);
  
        console.log(formdata);
        const response = await api.createBet(formdata);
        console.log(response);
        setLoader(false);
      
        if (response?.error) {
          throw new Error(response?.error);
        }
        showToast(response.msg);
        setModalShow(false)
        getAllBet()

      }else {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log(accounts[0]);
        let bettingContract = new web3.web3.eth.Contract(BettingABI, process.env.REACT_APP_BETTING_ADDRESS);
        console.log(bettingContract);
  
        let r = await bettingContract.methods.addBetType(web3.web3.utils.toWei(sendAmount,'ether')).send({
          from: accounts[0]
        });
        console.log(r.events.NewType.returnValues.Btype);
        
        let formdata = new FormData();
          formdata.append("cardImg", img, img.name);
          formdata.append("betType", r.events.NewType.returnValues.Btype);
          formdata.append("amount",  noExponents(sendAmount));
          formdata.append("noOfCards", data.noOfCards);
    
        console.log(formdata);
        const response = await api.createBet(formdata);
        console.log(response);
        setLoader(false);
       
        if (response?.error) {
          throw new Error(response?.error);
        }
        showToast(response.msg);
        setModalShow(false)
        getAllBet()
      }

    
    } catch (e) {
      setLoader(false);
      console.log('====================================');
      console.log(e);
      console.log('====================================');
      showToast("Error on bet creation");
    }
  };

  const validation = () => {

    if (!data.amount) {
      return "Amount is required";
    }
    if (!data.noOfCards) {
      return "No of card is required";
    }


    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };



  
  const getPoints = async()=> {
    if(authedAdmin?.authedAdmin?.user){
        const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users/get/points-userId`;
        let response = await axios.get(url,
            {headers: {
                Authorization: `${localStorage.getItem("admin")}`,
                'Content-Type': `application/json`
            }}
        );
        console.log(response);
        const point = response?.data?.points.reduce((accumulator, object) => {
            return accumulator + object.points;
          }, 0);
          console.log(point);
          setAdminPoint(point)
    }
}

  useEffect(() => {
    getPoints()
  }, [authedAdmin?.authedAdmin?.user])
  
  console.log(authedAdmin.authedAdmin.user);

    return (
      <>
        <AdminNavbar />
        <section className="createBetPage">
          {/* <Header2 /> */}
          <div className="container-fluid">
            {/* {authedAdmin?.authedAdmin?.user?.wallet ? */}
            <>
            {page == 1 &&
            <div>
              <div>
                <div className="createBetBtn">
                    <button className="themeBtn" onClick={()=>setModalShow(true)}>Add Bet</button>
                    <h3>Admin Points: {adminPoint}</h3>
                </div>
    
                <div className="table-responsive mt-4 homeTable">
                  <table className="table custom-table">
                    <thead>
                      <tr>
                        <th scope="col">Card Image</th>
                        <th scope="col">Bet Type</th>
                        <th scope="col">Amount</th>
                        <th scope="col">No. of cards</th>
                      </tr>
                    </thead>
                    <tbody>
                        {betData.map((item,key)=>(
                        <TableRow item={item} />
                        ))}
                    
                    </tbody>
                  </table>
                </div>
              </div>
                <div>
                  <TeamSection teams={teams} setTeams={setTeams} />
                  <GameSection games={games} setGames={setGames} teams={teams} setTeams={setTeams} />
                  <TournamentSection />
                </div>
            </div>
            }
            {page == 2 && 
            <>
              <UserPointsPage />
            </>
            }
            </>
             {/* :
             <h1 className="text-center">Please Connect Wallet</h1>
             } */}
           
          </div>
  
          {/* <Footer /> */}
        </section>
      
           <Modal
              show={modalShow}
              onHide={() => setModalShow(false)} 
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
           >
  
          <Modal.Body>
              <div className="betModal">
                  <div className="closeIcon" onClick={() => setModalShow(false)}>
                  <i className="fas fa-times"></i>
                  </div>
                <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
                  <h2 className="text-center">Create Bet  </h2>
                    {/* <div className="form-group">
                      <input
                        onChange={onInputChange}
                        name="betType"
                        className="text"
                        placeholder="betType"
                      />
                    </div> */}
                    <div className="form-group">
                      <input
                        className="text"
                        onChange={onInputChange}
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        step="any"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="text"
                        onChange={onInputChange}
                        type="number"
                        name="noOfCards"
                        placeholder="No. of cards to show for bet"
                      />
                    </div>
                    <div className="form-group">
                    <input
                        className="text"
                        onChange={(e)=> setImg(e.target.files[0])}
                        type="file"
                        name="cardImg"
                        placeholder="image"
                        accept="image/*"
                      />
                    </div>
                    <div className="text-center m-signin-submit">
                      <button onClick={onSubmit} className="btn">
                        Add
                      </button>
                    </div>
  
                </form>
              </div>
          </Modal.Body>
        </Modal>
        {/* <Loader loader={loader} /> */}
      </>
    )
};

const TableRow =({item}) => {
  return(
    <tr>
        <th>
          <div className="cardImg">
            <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${item?.cardImg}`} alt="image" />
          </div>
        </th>
        <td>{item?.betType}</td>
        <td>{item?.amount}</td>
        <td>{item?.noOfCards}</td>
    </tr>
  )
}


