import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import GameCard from "./GameCard";
import { CollapsedContext } from "../../context";

export default function GamesSection({ gameSectionObject }){
  const { collapsed } = useContext(CollapsedContext);
  return(
    <>
    <section className="classicGameSec">
            <div className="headMain">
              <div className="homeHead"><span>{gameSectionObject.sectionName}</span> Games</div>
              <div className="view">View All</div>
            </div>
            <div className="row">
            {gameSectionObject.games.slice(0,collapsed ? 5 : 5).map((game, i) => {
              return (
              <div className="col-xl-3 col-md-4 col-sm-6">
                <GameCard  i={i} key={`${i}-${game.name}`} game={game} />
              </div>
              );
            })}
            </div>
      </section>
    </>
  );
}

