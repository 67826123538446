import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import CaretRight from '../../imgs/CaretRight.svg';
import TournamentCard from "./tournamentCard";
import tournamentCardImg from '../../imgs/tournament-1.png'
import tournamentCardImg2 from '../../imgs/tournament-2.png'
import tournamentImg from '../../imgs/dashboard-tournament-1-ornge.svg'
import tournamentImg2 from '../../imgs/dashboard-tournament-1.svg'

const tournamentss = [
  {btnText: 'Register', btnClass: 'theme-btn', img: tournamentCardImg , bgColor: 'rgb(206, 144, 52, 0.4)', tournamentImg: tournamentImg},
  {btnText: 'Full', btnClass: 'theme-btn-green', img: tournamentCardImg2, bgColor: 'rgb(140, 200, 38, 0.4)', tournamentImg: tournamentImg2},
  {btnText: 'Register', btnClass: 'theme-btn', img: tournamentCardImg , bgColor: 'rgb(206, 144, 52, 0.4)', tournamentImg: tournamentImg},
  {btnText: 'Full', btnClass: 'theme-btn-green', img: tournamentCardImg2, bgColor: 'rgb(140, 200, 38, 0.4)', tournamentImg: tournamentImg2},
]

const spotValues = ['All', 'Spots Available', 'Spots Full'];

export default function TournamentSection(){
  const [tournaments, setTournaments] = useState([]);
  const [spotFilter, setSpotFilter] = useState(0);
  const updateTournament = (tourn) => {
    setTournaments(tournaments.map(t => {
      if (t._id === tourn._id){
        return {
          ...tourn,
          btnText: tourn.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS) ? 'Full' : 'Register',
          btnClass: tourn.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS) ? 'theme-btn-green' : 'theme-btn',
          img: tourn.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS) ? tournamentCardImg2 : tournamentCardImg,
          tournamentImg: tourn.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS) ? tournamentImg2 : tournamentImg
        }
      }
      else{
        return t;
      }
    }))
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/tournaments/current`)
    .then(r => r.json())
    .then(r => {
      if (r.success){
        setTournaments(r.tournaments.map(to => {
          return ({
            ...to,
            btnText: to.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS) ? 'Full' : 'Register',
            btnClass: to.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS) ? 'theme-btn-green' : 'theme-btn',
            img: to.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS) ? tournamentCardImg2 : tournamentCardImg,
            tournamentImg: to.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS) ? tournamentImg2 : tournamentImg
          });
        }));
      }
    })
  }, []);

  return(
    <Row className="mt-5 jc-center">
      <Col xs='12' className="mb-3" style={{display: 'flex', justifyContent: 'space-between',flexWrap:'wrap'}}>
        <div className="tournament-header">Tournaments</div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Dropdown className="tournament-dd">
            <Dropdown.Toggle id="tournament-dropdown-basic">
              {spotValues[spotFilter]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {spotValues.map((s, i) => (
                <Dropdown.Item onClick={() => setSpotFilter(i)} key={s} >{s}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <div className="tournament-prev">
            <img src={CaretRight} />
          </div>
          <div className="tournament-next">
            <img src={CaretRight} style={{transform: 'rotate(180deg)'}} />
          </div>
        </div>
      </Col>
      {/* <Col xs='2'></Col> */}
      {tournaments.filter(tt => {
        if (spotFilter === 0){return true}
        if (spotFilter === 1){
          return tt.participants.length !== parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS)
        }
        if (spotFilter === 2){
          return tt.participants.length === parseInt(process.env.REACT_APP_TOTAL_PARTICIPANTS)
        }
      }).map((tournament, i) => {
        return(
          <React.Fragment key={`${i}-${tournament._id}`}>
          <Col xs='12' sm='6' md='5' >
            <TournamentCard updateTournament={updateTournament} tournament={tournament} i={`${i}`} />
          </Col>
          {/* {((i+1)%2===0) ? <><Col xs='2'></Col><Col xs='2'></Col></> : <></>} */}
          </React.Fragment>
        );
      })}

    </Row>
  );
}